import React, { FC, useCallback, useEffect, useState } from "react";

import {
  AppointmentRes,
  AppointmentResultsPage,
  AppointmentSorts,
  AppointmentStatus,
  UpdateAppointmentReq,
} from "@n3oltd/k2.calls.sdk.appointments";
import { Alert, Divider, Empty, Form } from "antd";
import moment from "moment";
import { useSelector } from "react-redux";
import styled from "styled-components";

import {
  _callsAppointmentsClient,
  _callsClient,
} from "appRedux/models/base/K2RestClients";
import K2RestService from "appRedux/models/base/K2RestService";
import { IApiResponse } from "appRedux/models/common/ApiResponseModel";
import { TimezoneLookups } from "appRedux/modules/lookups/types";
import { DataActions } from "appRedux/modules/sharedTypes";
import IApplicationState from "appRedux/types";
import DateHelpers from "common/helpers/dates";
import UserAvatar from "components/avatars/userAvatar";
import { K2Message } from "components/k2Widgets";
import PaginationFooter from "components/k2Widgets/k2Grid/PaginationFooter";
import { InjectedK2Intl } from "components/k2Widgets/k2Localizations/types";
import K2Spin from "components/k2Widgets/k2Spin";
import { UIUtils } from "components/utils";
import { DEFAULT_PAGE_SIZE } from "constants/appConstants";

import AppointmentModal, { ASSINGEE_INFO } from "../AppointmentModal";
import CancelAppointmentModal from "../Outbound/CancelAppointmentModal";

const Container = styled.div`
  border-radius: 8px;
  box-shadow: ${({ theme }) => theme.n3o_card_shadow};
  min-width: 250px;
`;

const CallItem = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 10px;
  padding: 10px;
  padding-left: 0px;

  .section {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-grow: 1;

    p {
      margin: 0 10px 0 0;
      padding-bottom: 8px;

      span {
        display: block;
        color: ${({ theme }) => theme.grey_6};
      }
    }

    section {
      text-align: right;
      font-size: ${({ theme }) => theme.font_size_sm};
      color: ${({ theme }) => theme.grey_8};
      font-weight: ${({ theme }) => theme.font_weight_semi_bold};
      line-height: ${({ theme }) => theme.line_height_md};
    }
  }
`;

const FakeLinkCancel = styled.span<{ $disabled: boolean }>`
  color: ${({ theme }) => theme.grey_7};
  font-weight: ${({ theme }) => theme.font_weight_semi_bold};
  line-height: ${({ theme }) => theme.line_height_md};
  &:hover {
    text-decoration: none;
    color: ${({ theme }) => theme.red_5};
    cursor: ${({ $disabled }) => ($disabled ? "not-allowed" : "pointer")};
  }
`;

const FakeLinkReschedule = styled.span<{ $disabled: boolean }>`
  color: ${({ theme }) => theme.grey_7};
  font-weight: ${({ theme }) => theme.font_weight_semi_bold};
  line-height: ${({ theme }) => theme.line_height_md};
  &:hover {
    text-decoration: none;
    color: #2196f3;
    cursor: ${({ $disabled }) => ($disabled ? "not-allowed" : "pointer")};
  }
  &::before {
    content: "●";
    display: inline-block;
    margin-right: 3px;
    margin-left: 3px;
    font-size: ${({ theme }) => theme.font_size_sm};
    font-weight: ${({ theme }) => theme.font_weight_semi_bold};
    line-height: ${({ theme }) => theme.line_height_md};
  }
`;

const AppointmentAlert = styled(Alert)`
  background-color: ${({ theme }) => theme.grey_3} !important;
  font-weight: ${({ theme }) => theme.font_weight_semi_bold};
  font-size: ${({ theme }) => theme.font_size_sm};
  line-height: ${({ theme }) => theme.line_height_md};
`;

const AppointmentWrapper = styled.div<{
  $canCancel: boolean;
  $canReschedule: boolean;
}>`
  flex: 1;
  flex-direction: row;
  border-bottom: 1px solid ${({ theme }) => theme.grey_4};
  padding-bottom: 5px;

  &:hover {
    /* Styles to apply when hovering over the Section */
    ${FakeLinkCancel} {
      text-decoration: none;
      color: ${({ theme }) => theme.red_5};
      cursor: ${({ $canCancel }) => ($canCancel ? "not-allowed" : "pointer")};
    }

    ${FakeLinkReschedule} {
      text-decoration: none;
      color: #2196f3;
      cursor: ${({ $canReschedule }) =>
        $canReschedule ? "not-allowed" : "pointer"};
    }
  }
`;

const Appointments: FC<{
  agentId: string;
  k2Intl: InjectedK2Intl;
  timezones?: TimezoneLookups;
  openCallList: boolean;
}> = ({ agentId, k2Intl, openCallList, timezones }) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [openCancelModal, setOpenCancelModal] = useState<boolean>(false);
  const [openAppointmentModal, setOpenAppointmentModal] = useState<boolean>(
    false,
  );
  const [updateAppointmentForm] = Form.useForm();
  const [revisionId, setRevisionId] = useState<string>(null);
  const [
    appointmentList,
    setAppointmentList,
  ] = useState<AppointmentResultsPage>({
    items: [],
  });
  const [errors, setErrors] = useState<string[]>([]);
  const [updatingAppointment, setUpdatingAppointment] = useState<boolean>(
    false,
  );

  const { continuationToken } = appointmentList;

  const subscriptionId = useSelector(
    (state: IApplicationState) => state.subscription.users.k2Subscription.id,
  );
  const fetchAppointments = useCallback(
    async (continuationToken?: string) => {
      setLoading(true);
      const resp: IApiResponse<AppointmentResultsPage> = await K2RestService.toResponse(
        _callsAppointmentsClient.findAppointments({
          assignedTo: [agentId],
          sort: AppointmentSorts.DueAt_desc,
          continuationToken: continuationToken,
          pageSize: DEFAULT_PAGE_SIZE,
          status: [AppointmentStatus.Pending],
        }),
      );

      if (resp.error) {
        UIUtils.handleServerError(k2Intl, resp.error);
      } else {
        let updatedAppointments = resp.getResultOrDefault();
        if (continuationToken) {
          updatedAppointments = {
            items: [...appointmentList.items, ...updatedAppointments.items],
            continuationToken: updatedAppointments.continuationToken,
          };
        }
        setAppointmentList(updatedAppointments);
      }

      setLoading(false);
    },
    [k2Intl, appointmentList, agentId],
  );

  const onLoadMore = useCallback(() => {
    fetchAppointments(continuationToken);
  }, [fetchAppointments, continuationToken]);

  useEffect(() => {
    if (openCallList) {
      fetchAppointments();
    }
    // eslint-disable-next-line
  }, [openCallList]);

  const cancelAppointment = (item: AppointmentRes) => {
    if (item.canCancel) {
      setRevisionId(item.revisionId);
      setOpenCancelModal(true);
    }
  };

  const editAppointment = (item: AppointmentRes) => {
    if (item.canAssign) {
      setRevisionId(item.revisionId);
      const splitedDate = item.due.split(" ");
      const selectedTimezone = timezones.items.find(
        (x) => x.id === splitedDate[1].trim().toLowerCase(),
      );
      const dueAt = moment(splitedDate[0])
        .parseZone()
        .tz(selectedTimezone.id, true);

      let appointmentValues = {
        ["assignee"]: {
          ["id"]: item.assignedTo
            .id,
          ["type"]: item
            .assignedTo.type,
        },
        ["notes"]: {
          ["text"]: item.notes,
        },
        ["due"]: {
          ["at"]: {
            ["dateTime"]: dueAt,
            ["timezone"]: selectedTimezone?.id,
          },
        },
        [ASSINGEE_INFO]: [item.assignedTo.type, item.assignedTo.id],
      };
      updateAppointmentForm?.setFieldsValue(appointmentValues);
      setOpenAppointmentModal(true);
    }
  };

  const updateAppointment = useCallback(
    async (revisionId: string, req: UpdateAppointmentReq) => {
      setUpdatingAppointment(true);
      let response: IApiResponse<void> = await K2RestService.toResponse(
        _callsAppointmentsClient.updateAppointment(revisionId, req),
      );

      UIUtils.handleDataUpdateResult({
        k2Intl,
        status: {
          action: DataActions.update,
          ...response,
        },
        successTitle: "common.success.title",
        successMessage: "communication.cc.dialler.updateAppointment.success",
        onSuccess: () => {
          setOpenAppointmentModal(false);
          updateAppointmentForm.resetFields();
          fetchAppointments();
        },
        form: updateAppointmentForm,
      });
      setUpdatingAppointment(false);
    },
    [k2Intl, updateAppointmentForm, fetchAppointments],
  );
  return (
    <K2Spin spinning={loading}>
      <Container>
        {appointmentList?.items?.length === 0 ? (
          <Empty
            description={k2Intl.formatMessage({
              localeKey: "communication.cc.noAppointments",
            })}
          />
        ) : (
          <>
            {appointmentList?.items?.map((item) => (
              <CallItem key={item.revisionId}>
                <div>
                  <UserAvatar
                    size={40}
                    presetId={"large"}
                    subscriptionId={subscriptionId}
                    user={item.account}
                    className="n3o-avatar-border n3o-avatar-text-center"
                  />
                </div>
                <AppointmentWrapper
                  $canCancel={!item.canCancel}
                  $canReschedule={!item.canAssign}
                >
                  <div className="section">
                    <p>
                      <strong>{item.account.name}</strong>
                      <span>{item.account.reference.text}</span>
                    </p>
                    <section>
                      <span
                        className={item.isOverdue ? "n3o-text-danger" : null}
                      >
                        {DateHelpers.todayTomorrowOrPastDue(
                          moment(item.dueAt),
                          k2Intl,
                        )}
                      </span>
                      <br />
                      <FakeLinkCancel
                        $disabled={!item.canCancel}
                        onClick={() => cancelAppointment(item)}
                      >
                        <K2Message withoutSpan localeKey="common.cancel" />
                      </FakeLinkCancel>
                      <FakeLinkReschedule
                        $disabled={!item.canAssign}
                        onClick={() => editAppointment(item)}
                      >
                        <K2Message withoutSpan localeKey="common.update" />
                      </FakeLinkReschedule>
                    </section>
                  </div>
                  {item.notes && <AppointmentAlert message={item.notes} />}
                </AppointmentWrapper>
              </CallItem>
            ))}

            {appointmentList?.continuationToken && (
              <>
                <Divider />
                <PaginationFooter onLoadMore={onLoadMore} />
              </>
            )}
          </>
        )}
      </Container>
      <CancelAppointmentModal
        revisionId={revisionId}
        k2Intl={k2Intl}
        visible={openCancelModal}
        setOpenCancelModal={setOpenCancelModal}
        onCancel={fetchAppointments}
      />
      <AppointmentModal
        k2Intl={k2Intl}
        visible={openAppointmentModal}
        setOpenModal={setOpenAppointmentModal}
        form={updateAppointmentForm}
        timezones={timezones}
        isUpdate={true}
        revisionId={revisionId}
        onUpdate={updateAppointment}
        errors={errors}
        creatingOrUpdating={updatingAppointment}
      />
    </K2Spin>
  );
};

export default Appointments;
