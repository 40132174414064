import React, { useRef } from "react";

import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { CarouselRef } from "antd/lib/carousel";
import styled from "styled-components";

import k2Carousel, { IK2CarouselProps } from ".";
import K2Button from "../k2Buttons/K2Button";
import K2Message from "../k2Localizations/K2Message";

const CustomCarousel = styled(k2Carousel)`
  button {
    background: ${({ theme }) => theme.grey_7} !important;
  }
  .slick-dots-bottom {
    display: none !important;
  }
`;

export interface Ik2ArrowedCarouselProps extends IK2CarouselProps {
  totalItems: number;
  currentItem: number;
  entityLocaleKey: string;
  hideControls?: boolean;
}

const Counter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  font-size: 12px;
  color: ${({ theme }) => theme.grey_6};
`;

const CounterButtonWrapper = styled.div`
  bottom: 25px;
  width: 90%;
`;
const ButtonsArea = styled.div`
  display: flex;
  justify-content: flex-start;
`;
const LeftRightWrapper = styled.div`
  margin-left: auto;
`;

const K2ArrowedCarousel: React.FC<Ik2ArrowedCarouselProps> = (props) => {
  const {
    children,
    totalItems,
    currentItem,
    entityLocaleKey,
    hideControls,
    afterChange,
    ...rest
  } = props;
  const carouselRef = useRef<CarouselRef>(null);

  return (
    <>
      <CustomCarousel ref={carouselRef} afterChange={afterChange} {...rest}>
        {children}
      </CustomCarousel>

      {!hideControls && (
        <CounterButtonWrapper>
          <ButtonsArea>
            <Counter>
              {currentItem + 1} of {totalItems}{" "}
              <K2Message localeKey={entityLocaleKey} />
            </Counter>
            <LeftRightWrapper>
              <K2Button
                className={"n3o-mr-4"}
                onClick={() => {
                  carouselRef?.current?.prev();
                }}
                disabled={currentItem === 0}
              >
                <LeftOutlined />
              </K2Button>
              <K2Button
                onClick={() => {
                  carouselRef?.current?.next();
                }}
                disabled={currentItem === totalItems - 1}
              >
                <RightOutlined />
              </K2Button>
            </LeftRightWrapper>
          </ButtonsArea>
        </CounterButtonWrapper>
      )}
    </>
  );
};

export default K2ArrowedCarousel;
