import React, { useCallback, useEffect, useState } from "react";

import {
  QuickNavigationEntryRes,
  QuickNavigationGroupRes,
} from "@n3oltd/karakoram.navigation.sdk.quick-navigation/esm";
import { Col, Empty, Row, Tabs } from "antd";
import { useWindowWidth } from "hooks";
import { useSelector } from "react-redux";
import styled from "styled-components";

import { K2RouteKeys } from "appRedux/models/routes/RouteModel";
import IApplicationState from "appRedux/types";
import { useAppContext } from "common/contexts/AppProvider/AppProvider";
import { K2Card, K2Message, K2Tabs } from "components/k2Widgets";
import injectK2Intl from "components/k2Widgets/k2Localizations/injectK2Intl";
import { InjectedK2IntlProps } from "components/k2Widgets/k2Localizations/types";
import { N3oIcon } from "components/n3oIcon";
import { IN3OIconType } from "components/n3oIcon/types";
import QuickNavTile from "components/navigation/quickNavigation/QuickNavTile";
import { TAB_SIZE } from "constants/ThemeSetting";

const TabPane = Tabs.TabPane;

const ColWrapper = styled(Col)<{
  $loading: boolean;
  $isVisible: boolean;
  $isSmallScreen;
}>`
  margin-bottom: ${({ $isSmallScreen }) => ($isSmallScreen ? "30px" : "0")};
  visibility: ${({ $isVisible }) => ($isVisible ? "visible" : "hidden")};
  transition-timing-function: ${({ $isVisible }) =>
    $isVisible ? "ease-in" : "unset"};
  transition-delay: ${({ $isVisible }) => ($isVisible ? "300ms" : "0ms")};
  transition-duration: ${({ $isVisible }) => ($isVisible ? "300ms" : "0ms")};
  margin-top: ${({ $loading }) => ($loading ? "70px" : "32px")};
  transition: margin-top 1s ease;
`;

const CardWrapper = styled(K2Card)<{ $isVisible: boolean }>`
  -webkit-box-shadow: 0px 16px 40px -16px rgba(61, 105, 190, 0.25);
  -moz-box-shadow: 0px 16px 40px -16px rgba(61, 105, 190, 0.25);
  box-shadow: 0px 16px 40px -16px rgba(61, 105, 190, 0.25);

  // Hide the title on medium screens where it doesn't fit
  @media screen and (max-width: 1200px) {
    .ant-card-head-title {
      display: none !important;
    }
  }

  @media screen and (max-width: ${({ theme }) => theme.screen_tab}) {
    .ant-card-head-title {
      display: inline-block !important;
    }
  }

  transition-timing-function: ${({ $isVisible }) =>
    $isVisible ? "ease-in" : "unset"};
  transition-delay: ${({ $isVisible }) => ($isVisible ? "300ms" : "0ms")};
  transition-duration: ${({ $isVisible }) => ($isVisible ? "300ms" : "0ms")};
  transform: translateY(0px);

  .ant-card-head {
    .ant-card-head-wrapper {
      .ant-card-head-title {
        span {
          color: ${({ theme }) => theme.dark_3};
        }
      }
    }
  }

  .ant-card-body {
    margin-top: -68px;
    padding-bottom: 10px;

    .ant-tabs {
      .ant-tabs-nav {
        top: -1px;
        right: -24px;
        .ant-tabs-nav-wrap {
          display: block;
          .ant-tabs-nav-list {
            float: right;
          }
        }
        .ant-tabs-tab,
        .ant-tabs-tab-active {
          margin: 0 24px 0 0;
          width: 40px;
        }
        .ant-tabs-tab-btn {
          width: 100%;
          display: flex;
          justify-content: center;
          span {
            margin-right: 0;
          }
        }
      }
    }
  }
`;

interface IProps extends InjectedK2IntlProps {
  loading: boolean;
  quickLinks: QuickNavigationGroupRes[];
}

export interface QuickNavigationEntryResWithPermission
  extends QuickNavigationEntryRes {
  havePermission: boolean;
}

export interface QuickNavigationGroupResWithPermission
  extends QuickNavigationGroupRes {
  entries: QuickNavigationEntryResWithPermission[];
}

const QuickNavigation: React.FC<IProps> = ({ loading, quickLinks, k2Intl }) => {
  const [quickLinksWithPermissions, setQuickLinksWithPermissions] = useState<
    QuickNavigationGroupResWithPermission[]
  >(null);

  const isVisible = useSelector(
    (state: IApplicationState) => state.settings.quickNavVisible,
  );

  const k2UserRoutes = useSelector(
    (state: IApplicationState) => state.subscription.users.k2UserRoutes,
  );

  const addPermissionsToResponses = useCallback(
    (groups: QuickNavigationGroupRes[]) => {
      return groups.map((group) => {
        return {
          ...group,
          entries: group.entries.map((entry) => {
            return {
              ...entry,
              havePermission: k2UserRoutes?.items?.includes(entry.route),
            };
          }),
        };
      });
    },
    [k2UserRoutes],
  );

  useEffect(() => {
    if (quickLinks) {
      setQuickLinksWithPermissions(addPermissionsToResponses(quickLinks));
    }

    // eslint-disable-next-line
  }, [quickLinks]);

  const { theme } = useAppContext();

  const width: number = useWindowWidth();
  const isSmallScreen = width < TAB_SIZE;

  return (
    <ColWrapper
      xl={12}
      lg={12}
      md={24}
      sm={24}
      xs={24}
      className={`n3o-py-0 ${isSmallScreen ? "n3o-px-0" : "n3o-pr-0"}`}
      $loading={loading}
      $isVisible={isVisible}
      $isSmallScreen={isSmallScreen}
    >
      <CardWrapper
        title={loading ? "" : <K2Message localeKey={"app.quickNavigation"} />}
        loading={loading}
        $isVisible={isVisible}
        paragraph={{ rows: 2, width: "100%" }}
        className={"quick-nav-panel"}
      >
        {quickLinks ? (
          <K2Tabs animated>
            {quickLinksWithPermissions?.map?.((category, index) => (
              <TabPane
                tab={
                  <N3oIcon
                    icontype={category.icon as IN3OIconType}
                    width={18}
                    height={18}
                    fill={theme.icon_grey_light}
                  />
                }
                key={category.title + "_" + index}
              >
                <Row justify="start" gutter={24}>
                  {category.entries?.map?.((link) => (
                    <Col
                      span={6}
                      key={link.name}
                      style={{ minWidth: 100, marginBottom: 10 }}
                    >
                      <QuickNavTile
                        k2Intl={k2Intl}
                        link={{
                          icon: link.icon as IN3OIconType,
                          routeKey: link.route as K2RouteKeys,
                          title: link.name,
                          havePermission: link.havePermission,
                        }}
                      />
                    </Col>
                  ))}
                </Row>
              </TabPane>
            ))}
          </K2Tabs>
        ) : (
          <Empty imageStyle={{ height: "75px", marginTop: "80px" }} />
        )}
      </CardWrapper>
    </ColWrapper>
  );
};

export default injectK2Intl(QuickNavigation);
