import { LookupType as AccountLookups } from "@n3oltd/k2.accounts.sdk.lookups/esm";
import { LookupType as CallsLookups } from "@n3oltd/k2.calls.sdk.lookups/esm";
import { LookupType as CommunicationsLookups } from "@n3oltd/k2.communications.sdk.lookups/esm";
import { LookupType as DonationLookups } from "@n3oltd/k2.donations.sdk.lookups/esm";
import { LookupType as PledgesLookups } from "@n3oltd/k2.pledges.sdk.lookups/esm";
import { LookupType as SubscriptionLookups } from "@n3oltd/k2.subscriptions.sdk.lookups/esm";
import { LookupType as UsersLookups } from "@n3oltd/k2.users.sdk.lookups/esm";
import { LookupType as AnalyticsLookups } from "@n3oltd/karakoram.analytics.sdk.lookups/esm";
import { LookupType as DataLookups } from "@n3oltd/karakoram.dataimport.sdk.lookups/esm";
import { LookupType as EmailAdminLookups } from "@n3oltd/karakoram.emails.sdk.lookups/esm";
import { LookupType as FeedbackLookups } from "@n3oltd/karakoram.feedbacks.sdk.lookups/esm";
import { LookupType as CustomFormsLookup } from "@n3oltd/karakoram.forms.sdk.lookups";
import { LookupType as ListsLookups } from "@n3oltd/karakoram.lists.sdk.lookups/esm";
import { LookupType as MailLookups } from "@n3oltd/karakoram.mail.sdk.lookups/esm";
import { LookupType as PaymentsLookups } from "@n3oltd/karakoram.payments.sdk.lookups/esm";
import { LookupType as PrintLookups } from "@n3oltd/karakoram.print.sdk.lookups/esm";
import { LookupType as ReportingLookups } from "@n3oltd/karakoram.reporting.sdk.lookups/esm";
import { LookupType as SmsLookups } from "@n3oltd/karakoram.sms.sdk.lookups/esm";
import { LookupType as SponsorshipLookups } from "@n3oltd/karakoram.sponsorships.sdk.lookups/esm";
import { LookupType as TasksLookups } from "@n3oltd/karakoram.tasks.sdk.lookups/esm";
import { LookupType as TaxReliefLookups } from "@n3oltd/karakoram.taxrelief.sdk.lookups/esm";
import { LookupType as TemplatesLookups } from "@n3oltd/karakoram.templates.sdk.lookups/esm";
import { LookupType as TimeclockLookups } from "@n3oltd/karakoram.timeclock.sdk.lookups/esm";
import { LookupType as WebHooksLookups } from "@n3oltd/karakoram.webhooks.sdk.lookups/esm";
import { all, put, takeEvery } from "redux-saga/effects";

import { IApiResponse } from "appRedux/models/common/ApiResponseModel";
import {
  ActionTypes,
  FetchLookups,
} from "appRedux/modules/lookups/actionTypes";
import { K2LookupsResponse } from "appRedux/modules/lookups/types";

import {
  _accountLookupsClient,
  _analyticsLookupsClient,
  _callsLookupsClient,
  _communicationsLookupsClient,
  _dataLookupsClient,
  _donationsLookupsClient,
  _emailAdminLookupsClient,
  _feedbacksLookupsClient,
  _formsLookupClient,
  _listsLookupsClient,
  _mailLookupsClient,
  _paymentsLookupsClient,
  _pledgesLookupsClient,
  _printLookupsClient,
  _reportingLookupsClient,
  _smsLookupsClient,
  _sponsorshipLookupsClient,
  _subscriptionLookupsClient,
  _tasksLookupsClient,
  _taxReliefLookupsClient,
  _templatesLookupClient,
  _timeClockLookupsClient,
  _usersLookupsClient,
  _webHooksLookupsClient,
} from "../../models/base/K2RestClients";
import K2Service from "../../models/base/K2RestService";
import {
  LookupType,
  MergedLookup,
  MergedLookupMap,
} from "../../models/lookups/LookupsModel";
import { K2RestClient } from "./../../models/base/types";
import { MergedLookupType } from "./../../models/lookups/LookupsModel";
import * as lookupActions from "./actions";

//#region HELPER FUNCTIONS

interface LookupWithOptions {
  lookup: LookupType | MergedLookup;
  options?: LookupType[];
}

/**
 * The job of this function is to take an array of lookups, e.g. ["emailTypes", "countries"], and figure out which lookups
 * can be retrieved via a merged endpoint.
 * @param lookups
 */
const getLookupsWithOptions = (lookups: LookupType[]): LookupWithOptions[] => {
  const withOptions: LookupWithOptions[] = [];
  const tempWithOpts: { [key in MergedLookup]?: LookupType[] } = {};

  lookups.forEach((lookup) => {
    if (MergedLookupMap[lookup] && !tempWithOpts[MergedLookupMap[lookup]]) {
      tempWithOpts[MergedLookupMap[lookup]] = [lookup];
    } else if (MergedLookupMap[lookup]) {
      tempWithOpts[MergedLookupMap[lookup]].push(lookup);
    } else {
      tempWithOpts[lookup] = null;
    }
  });

  Object.keys(tempWithOpts).forEach((key: LookupType) => {
    withOptions.push({
      lookup: key,
      options: tempWithOpts[key],
    });
  });

  return withOptions;
};

const getAllLookups = (
  _restClient: K2RestClient,
  mergedOptions: MergedLookupType[],
): Promise<IApiResponse<K2LookupsResponse>> => {
  try {
    return K2Service.toResponse<K2LookupsResponse>(
      _restClient?.getAllLookups?.({ types: mergedOptions }),
    );
  } catch (e) {
    console.error(e);
  }
};

const getSingleLookups = (
  _restClient: K2RestClient,
  lookup: MergedLookupType,
): Promise<IApiResponse<K2LookupsResponse>> => {
  try {
    const actionName = "get" + lookup[0].toUpperCase() + lookup.slice(1);

    return K2Service.toResponse(
      _restClient?.[actionName]?.()?.then((results) => ({
        [lookup]: results,
      })),
    );
  } catch (e) {
    console.error(e);
  }
};

/**
 * This function serves as a mapping between a lookup type (including merged lookups)
 * and the API from which they should be fetched.
 * @param lookupType
 * @param mergedOptions
 */
const getLookupApiCall = (
  lookupType: LookupType | MergedLookup,
  mergedOptions?: LookupType[],
): Promise<IApiResponse<K2LookupsResponse>> => {
  let restClient: K2RestClient = null;

  switch (lookupType) {
    case "accountsMerged":
      restClient = _accountLookupsClient;
      mergedOptions = mergedOptions as AccountLookups[];
      break;
    case "analyticsMerged":
      restClient = _analyticsLookupsClient;
      mergedOptions = mergedOptions as AnalyticsLookups[];
      break;
    case "callsMerged":
      restClient = _callsLookupsClient;
      mergedOptions = mergedOptions as CallsLookups[];
      break;
    case "communicationsMerged":
      restClient = _communicationsLookupsClient;
      mergedOptions = mergedOptions as CommunicationsLookups[];
      break;
    case "donationsMerged":
      restClient = _donationsLookupsClient;
      mergedOptions = mergedOptions as DonationLookups[];
      break;
    case "emailAdminMerged":
      restClient = _emailAdminLookupsClient;
      mergedOptions = mergedOptions as EmailAdminLookups[];
      break;
    case "importsMerged":
      restClient = _dataLookupsClient;
      mergedOptions = mergedOptions as DataLookups[];
      break;
    case "listsMerged":
      restClient = _listsLookupsClient;
      mergedOptions = mergedOptions as ListsLookups[];
      break;
    case "mailMerged":
      restClient = _mailLookupsClient;
      mergedOptions = mergedOptions as MailLookups[];
      break;
    case "paymentsMerged":
      restClient = _paymentsLookupsClient;
      mergedOptions = mergedOptions as PaymentsLookups[];
      break;
    case "pledgesMerged":
      restClient = _pledgesLookupsClient;
      mergedOptions = mergedOptions as PledgesLookups[];
      break;
    case "printMerged":
      restClient = _printLookupsClient;
      mergedOptions = mergedOptions as PrintLookups[];
      break;
    case "smsMerged":
      restClient = _smsLookupsClient;
      mergedOptions = mergedOptions as SmsLookups[];
      break;
    case "sponsorshipsMerged":
      restClient = _sponsorshipLookupsClient;
      mergedOptions = mergedOptions as SponsorshipLookups[];
      break;
    case "subscriptionsMerged":
      restClient = _subscriptionLookupsClient;
      mergedOptions = mergedOptions as SubscriptionLookups[];
      break;
    case "tasksMerged":
      restClient = _tasksLookupsClient;
      mergedOptions = mergedOptions as TasksLookups[];
      break;
    case "taxReliefMerged":
      restClient = _taxReliefLookupsClient;
      mergedOptions = mergedOptions as TaxReliefLookups[];
      break;
    case "templatesMerged":
      restClient = _templatesLookupClient;
      mergedOptions = mergedOptions as TemplatesLookups[];
      break;
    case "timeclockMerged":
      restClient = _timeClockLookupsClient;
      mergedOptions = mergedOptions as TimeclockLookups[];
      break;
    case "usersMerged":
      restClient = _usersLookupsClient;
      mergedOptions = mergedOptions as UsersLookups[];
      break;
    case "webhooksMerged":
      restClient = _webHooksLookupsClient;
      mergedOptions = mergedOptions as WebHooksLookups[];
      break;
    case "feedbacksMerged":
      restClient = _feedbacksLookupsClient;
      mergedOptions = mergedOptions as FeedbackLookups[];
      break;
    case "reportingMerged":
      restClient = _reportingLookupsClient;
      mergedOptions = mergedOptions as ReportingLookups[];
      break;
    case "formsMerged":
      restClient = _formsLookupClient;
      mergedOptions = mergedOptions as CustomFormsLookup[];
      break;
    // LOOKUPS THAT DON'T WORK LIKE OTHER ONES
    case "queues":
      restClient = _printLookupsClient;
      mergedOptions = ["queues"];
      break;
  }

  if (mergedOptions?.length === 1) {
    return getSingleLookups(restClient, mergedOptions[0]);
  } else {
    return getAllLookups(restClient, mergedOptions);
  }
};

//#endregion

//#region WORKER FUNCTIONS

/**
 * This saga received a list of required lookups, which may include any LookupType, and
 * fetches each response from the relevant API.
 * @param action fetchRequiredLookups
 */
function* fetchRequiredLookups(action: FetchLookups) {
  try {
    const requiredLookups = action.lookupTypes;

    // If lookups requested individually, figure out whether they can be fetched from a merged endpoint
    const lookupsWithOptions = getLookupsWithOptions(requiredLookups);

    let lookupPromises: Promise<IApiResponse<K2LookupsResponse>>[] = [];

    lookupsWithOptions.forEach((lookupType) => {
      const promise = getLookupApiCall(lookupType.lookup, lookupType.options);
      if (promise) {
        lookupPromises.push(promise);
      }
    });

    if (lookupPromises.length > 0) {
      const responses: IApiResponse<K2LookupsResponse>[] = yield all(
        lookupPromises,
      );
      yield put(lookupActions.setLookups(responses));
    } else {
      yield put(lookupActions.setLookupsLoading(false));
    }
  } catch (e) {
    yield put(lookupActions.setLookupsLoading(false));
    console.log("error", e);
  }
}

//#endregion

export default function* rootSaga() {
  yield all([yield takeEvery(ActionTypes.FETCH_LOOKUPS, fetchRequiredLookups)]);
}
