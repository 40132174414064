import React, { useCallback, useState } from "react";

import {
  BulkCancelAppointmentsReq,
  BulkSelection,
  CancelAppointmentReq,
} from "@n3oltd/k2.calls.sdk.appointments";
import { Form, Input } from "antd";

import { _callsAppointmentsClient } from "appRedux/models/base/K2RestClients";
import K2Service from "appRedux/models/base/K2RestService";
import {
  IApiResponse,
  K2StatusCodes,
} from "appRedux/models/common/ApiResponseModel";
import { GeneralFormItemV4, HiddenFormItemV4 } from "components/formItems";
import { K2Modal } from "components/k2Widgets";
import { InjectedK2Intl } from "components/k2Widgets/k2Localizations/types";
import { UIUtils } from "components/utils";
import { showNotification } from "components/utils/Notification";

import ModalAlert from "../ModalAlert";

interface Props {
  k2Intl: InjectedK2Intl;
  visible: boolean;
  setOpenCancelModal: Function;
  onCancel: Function;
  revisionId?: string;
  selectedAppointment?: string[];
}

enum CancelType {
  Bulk = "bulk",
  Single = "single",
}

const notesFieldName = {
  [CancelType.Bulk]: [
    "cancel",
    "notes",
  ],
  [CancelType.Single]: "notes",
};

const messageKey = {
  bulk: {
    title: "communication.cc.dialler.cancelAppointments",
    success: "communication.cc.dialler.cancelAppointments.success",
    placeholder: "communication.cc.dialler.cancelAppointments.placeholder",
  },
  single: {
    title: "communication.cc.dialler.cancelAppointment",
    success: "communication.cc.dialler.cancelAppointment.success",
    placeholder: "communication.cc.dialler.cancelAppointment.placeholder",
  },
};

function CancelAppointmentModal({
  k2Intl,
  visible,
  setOpenCancelModal,
  onCancel,
  revisionId,
  selectedAppointment,
}: Props) {
  if (selectedAppointment === undefined && revisionId === null && visible) {
    throw new Error(`selectedAppointment or revisionId must be specified`);
  }
  const [form] = Form.useForm();
  const [cancelling, setCancelling] = useState(false);
  const [errors, setErrors] = useState([]);

  let cancelType = revisionId !== null ? CancelType.Single : CancelType.Bulk;
  const cancelAppointment = useCallback(async () => {
    form?.validateFields().then(async (values) => {
      setCancelling(true);

      const response: IApiResponse<void> = await K2Service.toResponse(
        cancelType === CancelType.Single
          ? _callsAppointmentsClient.cancelAppointment(revisionId as string, {
              notes: values.notes,
            })
          : _callsAppointmentsClient.bulkCancelAppointments(values),
      );
      if (response.error) {
        if (response.error.status === K2StatusCodes.preconditionFailed) {
          // Handles validation errors which can be matched to a specific field
          // and sets them on the form
          UIUtils.handleValidationErrors(response.error, form);

          const nonFieldRelatedErrors = UIUtils.getOverallValidationErrors(
            response.error,
            "error",
            "nonFieldRelated",
          );

          if (nonFieldRelatedErrors.length) {
            // Show these errors at the foot of the form
            setErrors(nonFieldRelatedErrors.map((err) => err.error));
          }
        } else {
          UIUtils.handleServerError(k2Intl, response.error);
        }
        setCancelling(false);
        return;
      }
      showNotification({
        type: "success",
        titleKey: "common.success.title",
        messageKey: messageKey[cancelType].success,
        k2Intl: k2Intl,
      });
      setOpenCancelModal(false);
      form?.resetFields();
      setCancelling(false);
      onCancel?.();
    });
  }, [form, cancelType, revisionId, k2Intl, setOpenCancelModal, onCancel]);

  return (
    <K2Modal
      titleKey={messageKey[cancelType].title}
      visible={visible}
      onOk={() => {
        form?.submit();
      }}
      okTextKey="common.cancel"
      onCancel={() => {
        form?.resetFields();
        setOpenCancelModal(false);
      }}
      okButtonProps={{
        danger: true,
        loading: cancelling,
        disabled: cancelling,
      }}
      hideCancel={true}
      centered
      withOutScroll
    >
      <Form form={form} onFinish={cancelAppointment}>
        {selectedAppointment?.length > 0 && (
          <>
            <HiddenFormItemV4
              fieldName={[
                "appointments",
                "ids",
              ]}
              initialValue={selectedAppointment}
            />
            <HiddenFormItemV4
              fieldName={[
                "appointments",
                "selection",
              ]}
              initialValue={BulkSelection.SpecifiedEntries}
            />
          </>
        )}

        <GeneralFormItemV4 name={notesFieldName[cancelType]} required>
          <Input.TextArea
            rows={4}
            placeholder={k2Intl.formatMessage({
              localeKey: messageKey[cancelType].placeholder,
            })}
          />
        </GeneralFormItemV4>
        {errors?.length > 0 && <ModalAlert errors={errors} />}
      </Form>
    </K2Modal>
  );
}

export default CancelAppointmentModal;
