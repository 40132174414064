import { AccountsClient } from "@n3oltd/k2.accounts.sdk.accounts";
import { IConfig } from "@n3oltd/k2.accounts.sdk.accounts";
import { AdminClient as AccountsAdminClient } from "@n3oltd/k2.accounts.sdk.admin";
import { DashboardClient as AccountsDashboardClient } from "@n3oltd/k2.accounts.sdk.dashboard";
import { DataEntryClient as AccountsDataEntryClient } from "@n3oltd/k2.accounts.sdk.data-entry";
import { DataImportClient as AccountsDataImportClient } from "@n3oltd/k2.accounts.sdk.data-import";
import { FormsClient as AccountFormsClient } from "@n3oltd/k2.accounts.sdk.forms";
import { GlobalSuggestionsClient as AccountsGlobalSuggestionsClient } from "@n3oltd/k2.accounts.sdk.global-suggestions";
import { LookupsClient as AccountLookupsClient } from "@n3oltd/k2.accounts.sdk.lookups";
import { StatisticsClient as AccountStatisticsClient } from "@n3oltd/k2.accounts.sdk.statistics";
import { AdminClient as CallsAdminClient } from "@n3oltd/k2.calls.sdk.admin";
import { AgentsClient as CallsAgentsClient } from "@n3oltd/k2.calls.sdk.agents";
import { AppointmentsClient } from "@n3oltd/k2.calls.sdk.appointments";
import { CallsClient } from "@n3oltd/k2.calls.sdk.calls";
import { CampaignsClient as CallsCampaignsClient } from "@n3oltd/k2.calls.sdk.campaigns";
import { DataEntryClient as CallsDataEntryClient } from "@n3oltd/k2.calls.sdk.data-entry";
import { DataImportClient as CallsDataImportClient } from "@n3oltd/k2.calls.sdk.data-import";
import { LookupsClient as CallsLookupsClient } from "@n3oltd/k2.calls.sdk.lookups";
import { RecordsClient as CallsRecordsClient } from "@n3oltd/k2.calls.sdk.records";
import { StatisticsClient as CallStatisticsClient } from "@n3oltd/k2.calls.sdk.statistics";
import { AdminClient as CommsAdminClient } from "@n3oltd/k2.communications.sdk.admin";
import { CorrespondenceClient } from "@n3oltd/k2.communications.sdk.correspondence";
import { DashboardClient as CommunicationsDashboardClient } from "@n3oltd/k2.communications.sdk.dashboard";
import { DataEntryClient as CommunicationsDataEntryClient } from "@n3oltd/k2.communications.sdk.data-entry";
import { LookupsClient as CommunicationsLookupsClient } from "@n3oltd/k2.communications.sdk.lookups";
import { OutboxesClient } from "@n3oltd/k2.communications.sdk.outboxes";
import { PreferencesClient as CommunicationsPreferencesClient } from "@n3oltd/k2.communications.sdk.preferences";
import { TriggersClient as CommunicationsTriggersClient } from "@n3oltd/k2.communications.sdk.triggers";
import { AdminClient as GivingsAdminClient } from "@n3oltd/k2.donations.sdk.admin";
import { AnalysisClient } from "@n3oltd/k2.donations.sdk.analysis";
import { DashboardClient as DonationsDashboardClient } from "@n3oltd/k2.donations.sdk.dashboard";
import { DataEntryClient as DonationsDataEntryClient } from "@n3oltd/k2.donations.sdk.data-entry";
import { DataImportClient as DonationsDataImportClient } from "@n3oltd/k2.donations.sdk.data-import";
import { DonationItemsClient } from "@n3oltd/k2.donations.sdk.donation-items";
import { DonationsClient } from "@n3oltd/k2.donations.sdk.donations";
import { GivingClient } from "@n3oltd/k2.donations.sdk.giving";
import { GlobalSuggestionsClient as DonationsGlobalSuggestionsClient } from "@n3oltd/k2.donations.sdk.global-suggestions";
import { LookupsClient as DonationsLookupsClient } from "@n3oltd/k2.donations.sdk.lookups";
import { StatementsClient as DonationsStatementClient } from "@n3oltd/k2.donations.sdk.statements";
import { StatisticsClient as DonationStatisticsClient } from "@n3oltd/k2.donations.sdk.statistics";
import { AdminClient as PledgesAdminClient } from "@n3oltd/k2.pledges.sdk.admin";
import { DataImportClient as PledgesDataImportClient } from "@n3oltd/k2.pledges.sdk.data-import";
import { GlobalSuggestionsClient as PledgesGlobalSuggestionsClient } from "@n3oltd/k2.pledges.sdk.global-suggestions";
import { LookupsClient as PledgesLookupsClient } from "@n3oltd/k2.pledges.sdk.lookups";
import { PledgesClient } from "@n3oltd/k2.pledges.sdk.pledges";
import { FundsClient } from "@n3oltd/k2.subscriptions.sdk.funds";
import { LookupsClient as SubscriptionLookups } from "@n3oltd/k2.subscriptions.sdk.lookups";
import { OrganizationClient } from "@n3oltd/k2.subscriptions.sdk.organization";
import { SubscriptionsClient } from "@n3oltd/k2.subscriptions.sdk.subscriptions";
import { DataImportClient as UsersDataImportClient } from "@n3oltd/k2.users.sdk.data-import";
import { LookupsClient as UsersLookupsClient } from "@n3oltd/k2.users.sdk.lookups";
import { RolesClient } from "@n3oltd/k2.users.sdk.roles";
import { SecurityPrincipalsClient } from "@n3oltd/k2.users.sdk.security-principals";
import { UsersClient } from "@n3oltd/k2.users.sdk.users";
import { ActivitiesClient } from "@n3oltd/karakoram.activities.sdk.activities";
import { AttributionClient } from "@n3oltd/karakoram.analytics.sdk.attribution";
import { DashboardClient as AnalyticsDashboardClient } from "@n3oltd/karakoram.analytics.sdk.dashboard";
import { DataEntryClient as AnalyticsDataEntryClient } from "@n3oltd/karakoram.analytics.sdk.data-entry";
import { FundraisersClient } from "@n3oltd/karakoram.analytics.sdk.fundraisers";
import { LookupsClient as AnalyticsLookupsClient } from "@n3oltd/karakoram.analytics.sdk.lookups";
import { MeasuresClient } from "@n3oltd/karakoram.analytics.sdk.measures";
import { ReportsClient } from "@n3oltd/karakoram.analytics.sdk.reports";
import { TouchpointsClient } from "@n3oltd/karakoram.analytics.sdk.touchpoints";
import { AdminClient as DataAdminClient } from "@n3oltd/karakoram.dataimport.sdk.admin";
import { DataEntryClient as DataImportDataEntryClient } from "@n3oltd/karakoram.dataimport.sdk.data-entry";
import { DataImportClient as DataImportDataImportClient } from "@n3oltd/karakoram.dataimport.sdk.data-import";
import { FeedsClient } from "@n3oltd/karakoram.dataimport.sdk.feeds";
import { LookupsClient as DataLookupsClient } from "@n3oltd/karakoram.dataimport.sdk.lookups";
import { QueuesClient } from "@n3oltd/karakoram.dataimport.sdk.queues";
import { RecordsClient } from "@n3oltd/karakoram.dataimport.sdk.records";
import { RunsClient } from "@n3oltd/karakoram.dataimport.sdk.runs";
import { TransformsClient } from "@n3oltd/karakoram.dataimport.sdk.transforms";
import { AdminClient as EmailAdminClient } from "@n3oltd/karakoram.emails.sdk.admin";
import { EmailsClient } from "@n3oltd/karakoram.emails.sdk.emails";
import { LookupsClient as EmailsLookupsClient } from "@n3oltd/karakoram.emails.sdk.lookups";
import { AdminClient as FeedbacksAdminClient } from "@n3oltd/karakoram.feedbacks.sdk.admin";
import { DashboardClient as FeedbacksDashboardClient } from "@n3oltd/karakoram.feedbacks.sdk.dashboard";
import { DataEntryClient as FeedbacksDataEntryClient } from "@n3oltd/karakoram.feedbacks.sdk.data-entry";
import { DataImportClient as FeedbacksDataImportClient } from "@n3oltd/karakoram.feedbacks.sdk.data-import";
import { FeedbacksClient } from "@n3oltd/karakoram.feedbacks.sdk.feedbacks";
import { GlobalSuggestionsClient as FeedbacksGlobalSuggestionsClient } from "@n3oltd/karakoram.feedbacks.sdk.global-suggestions";
import { LookupsClient as FeedbacksLookupsClient } from "@n3oltd/karakoram.feedbacks.sdk.lookups";
import { SchemesClient as FeedbackSchemesClient } from "@n3oltd/karakoram.feedbacks.sdk.schemes/esm";
import { AdminClient as FormsAdminClient } from "@n3oltd/karakoram.forms.sdk.admin";
import { DataEntryClient as FormsDataEntryClient } from "@n3oltd/karakoram.forms.sdk.data-entry";
import { LookupsClient as FormsLookupsClient } from "@n3oltd/karakoram.forms.sdk.lookups";
import { AccountsClient as ListAccountClient } from "@n3oltd/karakoram.lists.connectors.accounts.sdk.accounts";
import { CallsClient as ListsCallsConnectorClient } from "@n3oltd/karakoram.lists.connectors.calls.sdk.calls";
import { CorrespondenceClient as ListsCorrespondenceConnectorClient } from "@n3oltd/karakoram.lists.connectors.correspondence.sdk.correspondence";
import { GivingsClient as ListGivingsClient } from "@n3oltd/karakoram.lists.connectors.givings.sdk.givings";
import { MailchimpClient as ListsMailchimpConnectorClient } from "@n3oltd/karakoram.lists.connectors.mailchimp.sdk.mailchimp";
import { PledgesClient as ListPledgesClient } from "@n3oltd/karakoram.lists.connectors.pledges.sdk.pledges";
import { SendGridClient as ListsSendGridClient } from "@n3oltd/karakoram.lists.connectors.sendgrid.sdk.sendgrid";
import { SponsorshipsClient as ListSponsorshipsClient } from "@n3oltd/karakoram.lists.connectors.sponsorships.sdk.sponsorships";
import { TasksClient as ListsTaskConnectorClient } from "@n3oltd/karakoram.lists.connectors.tasks.sdk.tasks";
import { ConnectionsClient as ListConnectionsClient } from "@n3oltd/karakoram.lists.sdk.connections";
import { DashboardClient as ListsDataDashboardClient } from "@n3oltd/karakoram.lists.sdk.dashboard";
import { ExportsClient as ListsExportsClient } from "@n3oltd/karakoram.lists.sdk.exports";
import { FoldersClient } from "@n3oltd/karakoram.lists.sdk.folders";
import { ListsClient } from "@n3oltd/karakoram.lists.sdk.lists";
import { LookupsClient as ListsLookupsClient } from "@n3oltd/karakoram.lists.sdk.lookups";
import { StatisticsClient as ListStatisticsClient } from "@n3oltd/karakoram.lists.sdk.statistics";
import { DataEntryClient as MailDataEntryClient } from "@n3oltd/karakoram.mail.sdk.data-entry";
import { LookupsClient as MailLookupsClient } from "@n3oltd/karakoram.mail.sdk.lookups";
import { GettingStartedClient } from "@n3oltd/karakoram.navigation.sdk.getting-started";
import { GlobalSuggestionsClient as NavGlobalSuggestionsClient } from "@n3oltd/karakoram.navigation.sdk.global-suggestions";
import { QuickNavigationClient } from "@n3oltd/karakoram.navigation.sdk.quick-navigation";
import { RoutesClient } from "@n3oltd/karakoram.navigation.sdk.routes";
import { BamboraPaymentsClient } from "@n3oltd/karakoram.payments.processors.bambora.sdk.bambora";
import { BankTransferPaymentsClient } from "@n3oltd/karakoram.payments.processors.banktransfer.sdk.bank-transfer";
import { CardTerminalPaymentsClient } from "@n3oltd/karakoram.payments.processors.cardterminal.sdk.cardterminal";
import { CashPaymentsClient } from "@n3oltd/karakoram.payments.processors.cash.sdk.cash";
import { CharitiesTrustPaymentsClient } from "@n3oltd/karakoram.payments.processors.charitiestrust.sdk.charities-trust";
import { ChequePaymentsClient } from "@n3oltd/karakoram.payments.processors.cheque.sdk.cheque";
import { DirectDebitCAPaymentsClient } from "@n3oltd/karakoram.payments.processors.directdebitca.sdk.direct-debit-ca";
import { DirectDebitEUPaymentsClient } from "@n3oltd/karakoram.payments.processors.directdebiteu.sdk.direct-debit-eu";
import { DirectDebitUKPaymentsClient } from "@n3oltd/karakoram.payments.processors.directdebituk.sdk.direct-debit-uk";
import { GivebritePaymentsClient } from "@n3oltd/karakoram.payments.processors.givebrite.sdk.givebrite";
import { GoCardlessPaymentsClient } from "@n3oltd/karakoram.payments.processors.gocardless.sdk.gocardless";
import { HelloAssoPaymentsClient } from "@n3oltd/karakoram.payments.processors.helloasso.sdk.helloasso";
import { JustGivingPaymentsClient } from "@n3oltd/karakoram.payments.processors.justgiving.sdk.justgiving";
import { LaunchGoodPaymentsClient } from "@n3oltd/karakoram.payments.processors.launchgood.sdk.launchgood";
import { MuslimGivingPaymentsClient } from "@n3oltd/karakoram.payments.processors.muslimgiving.sdk.muslim-giving";
import { MyTenNightsPaymentsClient } from "@n3oltd/karakoram.payments.processors.mytennights.sdk.mytennights";
import { NetcashDebitOrderPaymentsClient } from "@n3oltd/karakoram.payments.processors.netcash.debitorder.sdk.netcash-debit-order";
import { NetcashPayNowPaymentsClient } from "@n3oltd/karakoram.payments.processors.netcash.paynow.sdk.netcash-pay-now";
import { OpayoPaymentsClient } from "@n3oltd/karakoram.payments.processors.opayo.sdk.opayo";
import { OtherPaymentsClient } from "@n3oltd/karakoram.payments.processors.other.sdk.other";
import { PayPalPaymentsClient } from "@n3oltd/karakoram.payments.processors.paypal.sdk.paypal";
import { SmartDebitPaymentsClient } from "@n3oltd/karakoram.payments.processors.smartdebit.sdk.smartdebit";
import { SquareTerminalPaymentsClient } from "@n3oltd/karakoram.payments.processors.squareterminal.sdk.squareterminal";
import { StripePaymentsClient } from "@n3oltd/karakoram.payments.processors.stripe.sdk.stripe";
import { VirginMoneyGivingPaymentsClient } from "@n3oltd/karakoram.payments.processors.virginmoneygiving.sdk.virgin-money-giving";
import { WorldlinePaymentsClient } from "@n3oltd/karakoram.payments.processors.worldline.sdk.worldline";
import { BankingClient } from "@n3oltd/karakoram.payments.sdk.banking";
import { CredentialsClient } from "@n3oltd/karakoram.payments.sdk.credentials";
import { DataEntryClient as PaymentsDataEntryClient } from "@n3oltd/karakoram.payments.sdk.data-entry";
import { DataImportClient as PaymentsDataImportClient } from "@n3oltd/karakoram.payments.sdk.data-import";
import { DirectDebitsClient } from "@n3oltd/karakoram.payments.sdk.direct-debits";
import { GlobalSuggestionsClient as PaymentsGlobalSuggestionsClient } from "@n3oltd/karakoram.payments.sdk.global-suggestions";
import { LookupsClient as PaymentsLookupsClient } from "@n3oltd/karakoram.payments.sdk.lookups";
import { MethodsClient as PaymentMethodsClient } from "@n3oltd/karakoram.payments.sdk.methods";
import { PaymentsClient } from "@n3oltd/karakoram.payments.sdk.payments";
import { ProcessorsClient as PaymentProcessorsClient } from "@n3oltd/karakoram.payments.sdk.processors";
import { RefundsClient } from "@n3oltd/karakoram.payments.sdk.refunds";
import { AdminClient as PrintAdminClient } from "@n3oltd/karakoram.print.sdk.admin";
import { BatchesClient } from "@n3oltd/karakoram.print.sdk.batches";
import { LookupsClient as PrintLookupsClient } from "@n3oltd/karakoram.print.sdk.lookups";
import { LookupsClient as ReportingLookupsClient } from "@n3oltd/karakoram.reporting.sdk.lookups";
import { ReportsClient as ReportingClient } from "@n3oltd/karakoram.reporting.sdk.reports";
import { AdminClient as SmsAdminClient } from "@n3oltd/karakoram.sms.sdk.admin";
import { LookupsClient as SmsLookupsClient } from "@n3oltd/karakoram.sms.sdk.lookups";
import { ChildSponsorshipClient } from "@n3oltd/karakoram.sponsorships.beneficiarytypes.child.sdk.child";
import { OtherSponsorshipClient } from "@n3oltd/karakoram.sponsorships.beneficiarytypes.other.sdk.other";
import { AdminClient as SponsorshipsAdminClient } from "@n3oltd/karakoram.sponsorships.sdk.admin";
import { BeneficiariesClient } from "@n3oltd/karakoram.sponsorships.sdk.beneficiaries";
import { DashboardClient as SponsorshipsDashboardClient } from "@n3oltd/karakoram.sponsorships.sdk.dashboard";
import { DataEntryClient as SponsorshipsDataEntryClient } from "@n3oltd/karakoram.sponsorships.sdk.data-entry";
import { DataImportClient as SponsorshipsDataImportClient } from "@n3oltd/karakoram.sponsorships.sdk.data-import";
import { GlobalSuggestionsClient as SponsorshipsGlobalSuggestionsClient } from "@n3oltd/karakoram.sponsorships.sdk.global-suggestions";
import { LookupsClient as SponsorshipLookups } from "@n3oltd/karakoram.sponsorships.sdk.lookups";
import { SchemesClient } from "@n3oltd/karakoram.sponsorships.sdk.schemes";
import { SponsorshipsClient } from "@n3oltd/karakoram.sponsorships.sdk.sponsorships";
import { GlobalSuggestionsClient as TaskGlobalSuggestionsClient } from "@n3oltd/karakoram.tasks.sdk.global-suggestions";
import { LookupsClient as TasksLookupsClient } from "@n3oltd/karakoram.tasks.sdk.lookups";
import { TasksClient } from "@n3oltd/karakoram.tasks.sdk.tasks";
import { AdminClient as TaxReliefAdminClient } from "@n3oltd/karakoram.taxrelief.sdk.admin";
import { ClaimsClient as TaxReliefClaimsClient } from "@n3oltd/karakoram.taxrelief.sdk.claims";
import { DataEntryClient as TaxReliefDataEntryClient } from "@n3oltd/karakoram.taxrelief.sdk.data-entry";
import { DeclarationsClient as TaxReliefDeclarationsClient } from "@n3oltd/karakoram.taxrelief.sdk.declarations";
import { DonationsClient as TaxReliefDonationsClient } from "@n3oltd/karakoram.taxrelief.sdk.donations";
import { JobsClient as TaxlReliefJobsClient } from "@n3oltd/karakoram.taxrelief.sdk.jobs";
import { LookupsClient as TaxReliefLookupsClient } from "@n3oltd/karakoram.taxrelief.sdk.lookups";
import { SchemesClient as TaxSchemeClient } from "@n3oltd/karakoram.taxrelief.sdk.schemes";
import { StatementsClient as TaxReliefStatementsClient } from "@n3oltd/karakoram.taxrelief.sdk.statements";
import { StatisticsClient as TaxReliefStatisticsClient } from "@n3oltd/karakoram.taxrelief.sdk.statistics";
import { TaxAccountsClient } from "@n3oltd/karakoram.taxrelief.sdk.tax-accounts";
import { AssetsClient } from "@n3oltd/karakoram.templates.sdk.assets";
import { EmailClient as EmailCompositionsClient } from "@n3oltd/karakoram.templates.sdk.email";
import { FragmentsClient } from "@n3oltd/karakoram.templates.sdk.fragments";
import { LookupsClient as TemplatesLookupsClient } from "@n3oltd/karakoram.templates.sdk.lookups";
import { PdfClient as PdfCompositionsClient } from "@n3oltd/karakoram.templates.sdk.pdf";
import { SmsClient as SmsCompositionsClient } from "@n3oltd/karakoram.templates.sdk.sms";
import { AdminClient as TimeClockAdminClient } from "@n3oltd/karakoram.timeclock.sdk.admin";
import { LookupsClient as TimeClockLookupsClient } from "@n3oltd/karakoram.timeclock.sdk.lookups";
import { EndpointsClient as WebHooksClient } from "@n3oltd/karakoram.webhooks.sdk.endpoints";
import { LookupsClient as WebHooksLoopupClient } from "@n3oltd/karakoram.webhooks.sdk.lookups";
import { InboxesClient} from "@n3oltd/karakoram.reporting.sdk.inboxes";


import AppManager from "appRedux/AppManager";
import K2RestService from "appRedux/models/base/K2RestService";

import { IBaseUrlKeys, IK2RestClients, RestClient } from "./types";

export let _accountLookupsClient: AccountLookupsClient = null;
export let _accountStatisticsClient: AccountStatisticsClient = null;
export let _accountsAdminClient: AccountsAdminClient = null;
export let _accountsClient: AccountsClient = null;
export let _accountsDashboardClient: AccountsDashboardClient = null;
export let _accountsDataEntryClient: AccountsDataEntryClient = null;
export let _accountsDataImportClient: AccountsDataImportClient = null;
export let _accountsGlobalSuggestionsClient: AccountsGlobalSuggestionsClient = null;
export let _accountFormsClient: AccountFormsClient = null;
export let _activitiesClient: ActivitiesClient = null;
export let _analysisClient: AnalysisClient = null;
export let _analyticsDashboardClient: AnalyticsDashboardClient = null;
export let _analyticsDataEntryClient: AnalyticsDataEntryClient = null;
export let _analyticsLookupsClient: AnalyticsLookupsClient = null;
export let _assetsClient: AssetsClient = null;
export let _attributionClient: AttributionClient = null;
export let _bamboraPaymentsClient: BamboraPaymentsClient = null;
export let _bankTransferPaymentsClient: BankTransferPaymentsClient = null;
export let _bankingClient: BankingClient = null;
export let _printBatchesClient: BatchesClient = null;
export let _cashPaymentsClient: CashPaymentsClient = null;
export let _chequePaymentsClient: ChequePaymentsClient = null;
export let _childSponsorshipClient: ChildSponsorshipClient = null;
export let _otherSponsorshipClient: OtherSponsorshipClient = null;
export let _callsAdminClient: CallsAdminClient = null;
export let _callsAgentsClient: CallsAgentsClient = null;
export let _callsAppointmentsClient: AppointmentsClient = null;
export let _callsCampaignsClient: CallsCampaignsClient = null;
export let _callsClient: CallsClient = null;
export let _callsDataImportClient: CallsDataImportClient = null;
export let _callsLookupsClient: CallsLookupsClient = null;
export let _callsStatisticsClient: CallStatisticsClient = null;
export let _callsDataEntryClient: CallsDataEntryClient = null;
export let _callsRecordsClient: CallsRecordsClient = null;
export let _communicationsAdminClient: CommsAdminClient = null;
export let _communicationsDashboardClient: CommunicationsDashboardClient = null;
export let _communicationsDataEntryClient: CommunicationsDataEntryClient = null;
export let _communicationsLookupsClient: CommunicationsLookupsClient = null;
export let _communicationsPreferencesClient: CommunicationsPreferencesClient = null;
export let _communicationsTriggersClient: CommunicationsTriggersClient = null;
export let _correspondenceClient: CorrespondenceClient = null;
export let _credentialsClient: CredentialsClient = null;
export let _dataAdminClient: DataAdminClient = null;
export let _dataImportDataEntryClient: DataImportDataEntryClient = null;
export let _dataImportDataImportClient: DataImportDataImportClient = null;
export let _dataLookupsClient: DataLookupsClient = null;
export let _directDebitUKPaymentsClient: DirectDebitUKPaymentsClient = null;
export let _directDebitEUPaymentsClient: DirectDebitEUPaymentsClient = null;
export let _directDebitCAPaymentsClient: DirectDebitCAPaymentsClient = null;
export let _netcashDebitOrderPaymentsClient: NetcashDebitOrderPaymentsClient = null;
export let _netcashPayNowPaymentsClient: NetcashPayNowPaymentsClient = null;
export let _directDebitsClient: DirectDebitsClient = null;
export let _donationItemsClient: DonationItemsClient = null;
export let _donationStatisticsClient: DonationStatisticsClient = null;
export let _donationsStatementClient: DonationsStatementClient = null;
export let _donationsClient: DonationsClient = null;
export let _donationsDashboardClient: DonationsDashboardClient = null;
export let _donationsDataEntryClient: DonationsDataEntryClient = null;
export let _donationsDataImportClient: DonationsDataImportClient = null;
export let _donationsGlobalSuggestionsClient: DonationsGlobalSuggestionsClient = null;
export let _donationsLookupsClient: DonationsLookupsClient = null;
export let _emailAdminClient: EmailAdminClient = null;
export let _emailAdminLookupsClient: EmailsLookupsClient = null;
export let _emailCompositionsClient: EmailCompositionsClient = null;
export let _emailsClient: EmailsClient = null;
export let _feedbacksClient: FeedbacksClient = null;
export let _feedbacksAdminClient: FeedbacksAdminClient = null;
export let _feedbacksLookupsClient: FeedbacksLookupsClient = null;
export let _feedbacksDataImportClient: FeedbacksDataImportClient = null;
export let _feedbackSchemesClient: FeedbackSchemesClient = null;
export let _feedbacksDataEntryClient: FeedbacksDataEntryClient = null;
export let _feedbacksDashboardClient: FeedbacksDashboardClient = null;
export let _feedbacksGlobalSuggestionsClient: FeedbacksGlobalSuggestionsClient = null;
export let _feedsClient: FeedsClient = null;
export let _fragmentsClient: FragmentsClient = null;
export let _formsAdminClient: FormsAdminClient = null;
export let _formsLookupClient: FormsLookupsClient = null;
export let _formsDataEntryClient: FormsDataEntryClient = null;
export let _gettingStartedClient: GettingStartedClient = null;
export let _givingClient: GivingClient = null;
export let _givingsAdminClient: GivingsAdminClient = null;
export let _givebritePaymentsClient: GivebritePaymentsClient = null;
export let _goCardlessPaymentsClient: GoCardlessPaymentsClient = null;
export let _justGivingPaymentsClient: JustGivingPaymentsClient = null;
export let _launchGoodPaymentsClient: LaunchGoodPaymentsClient = null;
export let _charitiesTrustPaymentsClient: CharitiesTrustPaymentsClient = null;
export let _listsCallsConnectorClient: ListsCallsConnectorClient = null;
export let _listConnectionsClient: ListConnectionsClient = null;
export let _listsClient: ListsClient = null;
export let _listsFolderClient: FoldersClient = null;
export let _listsCorrespondenceConnectorClient: ListsCorrespondenceConnectorClient = null;
export let _listsDataDashboardClient: ListsDataDashboardClient = null;
export let _listsExportsClient: ListsExportsClient = null;
export let _listsLookupsClient: ListsLookupsClient = null;
export let _listStatisticsClient: ListStatisticsClient = null;
export let _mailDataEntryClient: MailDataEntryClient = null;
export let _mailLookupsClient: MailLookupsClient = null;
export let _listsMailchimpConnectorClient: ListsMailchimpConnectorClient = null;
export let _measuresClient: MeasuresClient = null;
export let _myTenNightsPaymentsClient: MyTenNightsPaymentsClient = null;
export let _muslimGivingPaymentsClient: MuslimGivingPaymentsClient = null;
export let _navGlobalSuggestionsClient: NavGlobalSuggestionsClient = null;
export let _opayoPaymentsClient: OpayoPaymentsClient = null;
export let _otherPaymentsClient: OtherPaymentsClient = null;
export let _outboxesClient: OutboxesClient = null;
export let _inboxesClient: InboxesClient = null;
export let _timeClockAdminClient: TimeClockAdminClient = null;
export let _timeClockLookupsClient: TimeClockLookupsClient = null;
export let _payPalPaymentsClient: PayPalPaymentsClient = null;
export let _paymentDataEntryClient: PaymentsDataEntryClient = null;
export let _paymentMethodsClient: PaymentMethodsClient = null;
export let _paymentProcessorsClient: PaymentProcessorsClient = null;
export let _paymentsClient: PaymentsClient = null;
export let _paymentsDataImportClient: PaymentsDataImportClient = null;
export let _paymentsGlobalSuggestionsClient: PaymentsGlobalSuggestionsClient = null;
export let _paymentsLookupsClient: PaymentsLookupsClient = null;
export let _pdfCompositionsClient: PdfCompositionsClient = null;
export let _pledgesAdminClient: PledgesAdminClient = null;
export let _pledgesClient: PledgesClient = null;
export let _pledgesDataImportClient: PledgesDataImportClient = null;
export let _pledgesGlobalSuggestionsClient: PledgesGlobalSuggestionsClient = null;
export let _pledgesLookupsClient: PledgesLookupsClient = null;
export let _printLookupsClient: PrintLookupsClient = null;
export let _printAdminClient: PrintAdminClient = null;
export let _queuesClient: QueuesClient = null;
export let _quickNavigationClient: QuickNavigationClient = null;
export let _recordsClient: RecordsClient = null;
export let _reportsClient: ReportsClient = null;
export let _reportingClient: ReportingClient = null;
export let _reportingLookupsClient: ReportingLookupsClient = null;
export let _routesClient: RoutesClient = null;
export let _runsClient: RunsClient = null;
export let _securityPrincipalsClient: SecurityPrincipalsClient = null;
export let _smartDebitPaymentsClient: SmartDebitPaymentsClient = null;
export let _smsAdminClient: SmsAdminClient = null;
export let _smsLookupsClient: SmsLookupsClient = null;
export let _smsCompositionsClient: SmsCompositionsClient = null;
export let _sponsorshipBeneficiariesClient: BeneficiariesClient = null;
export let _sponsorshipLookupsClient: SponsorshipLookups = null;
export let _sponsorshipSchemesClient: SchemesClient = null;
export let _sponsorshipsAdminClient: SponsorshipsAdminClient = null;
export let _sponsorshipsClient: SponsorshipsClient = null;
export let _sponsorshipsDashboardClient: SponsorshipsDashboardClient = null;
export let _sponsorshipsDataEntryClient: SponsorshipsDataEntryClient = null;
export let _sponsorshipsDataImportClient: SponsorshipsDataImportClient = null;
export let _sponsorshipsGlobalSuggestionsClient: SponsorshipsGlobalSuggestionsClient = null;
export let _stripePaymentsClient: StripePaymentsClient = null;
export let _subscriptionFundsClient: FundsClient = null;
export let _subscriptionLookupsClient: SubscriptionLookups = null;
export let _subscriptionOrganizationsClient: OrganizationClient = null;
export let _subscriptionsClient: SubscriptionsClient = null;
export let _listsTaskConnectorClient: ListsTaskConnectorClient = null;
export let _taskGlobalSuggestionsClient: TaskGlobalSuggestionsClient = null;
export let _tasksClient: TasksClient = null;
export let _tasksLookupsClient: TasksLookupsClient = null;
export let _taxReliefAccountsClient: TaxAccountsClient = null;
export let _taxReliefDonationsClient: TaxReliefDonationsClient = null;
export let _taxReliefDataEntryClient: TaxReliefDataEntryClient = null;
export let _taxReliefDeclarationsClient: TaxReliefDeclarationsClient = null;
export let _taxReliefLookupsClient: TaxReliefLookupsClient = null;
export let _taxReliefStatementsClient: TaxReliefStatementsClient = null;
export let _taxReliefStatisticsClient: TaxReliefStatisticsClient = null;
export let _taxReliefClaimsClient: TaxReliefClaimsClient = null;
export let _taxReliefJobsClient: TaxlReliefJobsClient = null;
export let _taxReliefAdminClient: TaxReliefAdminClient = null;
export let _templatesLookupClient: TemplatesLookupsClient = null;
export let _touchpointsClient: TouchpointsClient = null;
export let _transformsClient: TransformsClient = null;
export let _usersLookupsClient: UsersLookupsClient = null;
export let _rolesClient: RolesClient = null;
export let _usersClient: UsersClient = null;
export let _usersDataImportClient: UsersDataImportClient = null;
export let _virginMoneyGivingPaymentsClient: VirginMoneyGivingPaymentsClient = null;
export let _webHooksLookupsClient: WebHooksLoopupClient = null;
export let _webHooksClient: WebHooksClient = null;
export let _worldlinePaymentsClient: WorldlinePaymentsClient = null;
export let _taxSchemeClient: TaxSchemeClient = null;
export let _refundClient: RefundsClient = null;
export let _fundraisersClient: FundraisersClient = null;
export let _listAccountClient: ListAccountClient = null;
export let _listGivingsClient: ListGivingsClient = null;
export let _listPledgesClient: ListPledgesClient = null;
export let _listSponsorshipsClient: ListSponsorshipsClient = null;
export let _listsSendGridClient: ListsSendGridClient = null;
export let _helloAssoPaymentClient: HelloAssoPaymentsClient = null;
export let _cardTerminalPaymentClient: CardTerminalPaymentsClient = null;
export let _squareTerminalPaymentClient: SquareTerminalPaymentsClient = null;

class K2RestClients implements IK2RestClients {
  initialize() {
    _accountLookupsClient = this.initRestClient(
      AccountLookupsClient,
      "accounts",
    );
    _accountStatisticsClient = this.initRestClient(
      AccountStatisticsClient,
      "accounts",
    );
    _accountsAdminClient = this.initRestClient(AccountsAdminClient, "accounts");
    _accountsClient = this.initRestClient(AccountsClient, "accounts");
    _accountsDashboardClient = this.initRestClient(
      AccountsDashboardClient,
      "accounts",
    );
    _accountsDataEntryClient = this.initRestClient(
      AccountsDataEntryClient,
      "accounts",
    );
    _accountsDataImportClient = this.initRestClient(
      AccountsDataImportClient,
      "accounts",
    );
    _accountsGlobalSuggestionsClient = this.initRestClient(
      AccountsGlobalSuggestionsClient,
      "accounts",
    );
    _accountFormsClient = this.initRestClient(AccountFormsClient, "accounts");

    _activitiesClient = this.initRestClient(ActivitiesClient, "activities");
    _analyticsDataEntryClient = this.initRestClient(
      AnalyticsDataEntryClient,
      "analytics",
    );
    _analyticsLookupsClient = this.initRestClient(
      AnalyticsLookupsClient,
      "analytics",
    );
    _analyticsDashboardClient = this.initRestClient(
      AnalyticsDashboardClient,
      "analytics",
    );
    _assetsClient = this.initRestClient(AssetsClient, "templates");
    _attributionClient = this.initRestClient(AttributionClient, "analytics");
    _bamboraPaymentsClient = this.initRestClient(
      BamboraPaymentsClient,
      "payments",
    );
    _bankTransferPaymentsClient = this.initRestClient(
      BankTransferPaymentsClient,
      "payments",
    );
    _bankingClient = this.initRestClient(BankingClient, "payments");
    _printBatchesClient = this.initRestClient(BatchesClient, "print");
    _callsAdminClient = this.initRestClient(CallsAdminClient, "calls");
    _callsAgentsClient = this.initRestClient(CallsAgentsClient, "calls");
    _callsAppointmentsClient = this.initRestClient(AppointmentsClient, "calls");
    _callsClient = this.initRestClient(CallsClient, "calls");
    _callsDataImportClient = this.initRestClient(
      CallsDataImportClient,
      "calls",
    );
    _callsLookupsClient = this.initRestClient(CallsLookupsClient, "calls");
    _callsCampaignsClient = this.initRestClient(CallsCampaignsClient, "calls");
    _callsStatisticsClient = this.initRestClient(CallStatisticsClient, "calls");
    _callsDataEntryClient = this.initRestClient(CallsDataEntryClient, "calls");
    _callsRecordsClient = this.initRestClient(CallsRecordsClient, "calls");
    _cashPaymentsClient = this.initRestClient(CashPaymentsClient, "payments");
    _chequePaymentsClient = this.initRestClient(
      ChequePaymentsClient,
      "payments",
    );
    _communicationsAdminClient = this.initRestClient(
      CommsAdminClient,
      "communications",
    );
    _communicationsDashboardClient = this.initRestClient(
      CommunicationsDashboardClient,
      "communications",
    );
    _communicationsDataEntryClient = this.initRestClient(
      CommunicationsDataEntryClient,
      "communications",
    );
    _communicationsLookupsClient = this.initRestClient(
      CommunicationsLookupsClient,
      "communications",
    );
    _communicationsPreferencesClient = this.initRestClient(
      CommunicationsPreferencesClient,
      "communications",
    );
    _communicationsTriggersClient = this.initRestClient(
      CommunicationsTriggersClient,
      "communications",
    );
    _correspondenceClient = this.initRestClient(
      CorrespondenceClient,
      "communications",
    );
    _credentialsClient = this.initRestClient(CredentialsClient, "payments");
    _dataAdminClient = this.initRestClient(DataAdminClient, "dataimport");
    _dataImportDataEntryClient = this.initRestClient(
      DataImportDataEntryClient,
      "dataimport",
    );
    _dataImportDataImportClient = this.initRestClient(
      DataImportDataImportClient,
      "dataimport",
    );
    _dataLookupsClient = this.initRestClient(DataLookupsClient, "dataimport");
    _directDebitUKPaymentsClient = this.initRestClient(
      DirectDebitUKPaymentsClient,
      "payments",
    );
    _directDebitEUPaymentsClient = this.initRestClient(
      DirectDebitEUPaymentsClient,
      "payments",
    );
    _directDebitCAPaymentsClient = this.initRestClient(
      DirectDebitCAPaymentsClient,
      "payments",
    );
    _netcashDebitOrderPaymentsClient = this.initRestClient(
      NetcashDebitOrderPaymentsClient,
      "payments",
    );
    _netcashPayNowPaymentsClient = this.initRestClient(
      NetcashPayNowPaymentsClient,
      "payments",
    );
    _directDebitsClient = this.initRestClient(DirectDebitsClient, "payments");
    _donationItemsClient = this.initRestClient(
      DonationItemsClient,
      "donations",
    );
    _donationStatisticsClient = this.initRestClient(
      DonationStatisticsClient,
      "donations",
    );
    _donationsClient = this.initRestClient(DonationsClient, "donations");
    _donationsDashboardClient = this.initRestClient(
      DonationsDashboardClient,
      "donations",
    );
    _donationsDataEntryClient = this.initRestClient(
      DonationsDataEntryClient,
      "donations",
    );
    _donationsDataImportClient = this.initRestClient(
      DonationsDataImportClient,
      "donations",
    );
    _donationsGlobalSuggestionsClient = this.initRestClient(
      DonationsGlobalSuggestionsClient,
      "donations",
    );
    _donationsLookupsClient = this.initRestClient(
      DonationsLookupsClient,
      "donations",
    );
    _donationsStatementClient = this.initRestClient(
      DonationsStatementClient,
      "donations",
    );
    _analysisClient = this.initRestClient(AnalysisClient, "donations");
    _emailAdminClient = this.initRestClient(EmailAdminClient, "emails");
    _emailAdminLookupsClient = this.initRestClient(
      EmailsLookupsClient,
      "emails",
    );
    _emailCompositionsClient = this.initRestClient(
      EmailCompositionsClient,
      "templates",
    );
    _emailsClient = this.initRestClient(EmailsClient, "emails");
    _feedbacksDataImportClient = this.initRestClient(
      FeedbacksDataImportClient,
      "feedbacks",
    );
    _feedbacksClient = this.initRestClient(FeedbacksClient, "feedbacks");
    _feedbacksAdminClient = this.initRestClient(
      FeedbacksAdminClient,
      "feedbacks",
    );
    _feedbackSchemesClient = this.initRestClient(
      FeedbackSchemesClient,
      "feedbacks",
    );
    _feedbacksDataEntryClient = this.initRestClient(
      FeedbacksDataEntryClient,
      "feedbacks",
    );
    _feedbacksDashboardClient = this.initRestClient(
      FeedbacksDashboardClient,
      "feedbacks",
    );
    _feedbacksGlobalSuggestionsClient = this.initRestClient(
      FeedbacksGlobalSuggestionsClient,
      "feedbacks",
    );
    _feedsClient = this.initRestClient(FeedsClient, "dataimport");
    _feedbacksLookupsClient = this.initRestClient(
      FeedbacksLookupsClient,
      "feedbacks",
    );
    _fragmentsClient = this.initRestClient(FragmentsClient, "templates");

    _formsLookupClient = this.initRestClient(FormsLookupsClient, "forms");
    _formsDataEntryClient = this.initRestClient(FormsDataEntryClient, "forms");
    _formsAdminClient = this.initRestClient(FormsAdminClient, "forms");

    _gettingStartedClient = this.initRestClient(
      GettingStartedClient,
      "navigation",
    );
    _givingClient = this.initRestClient(GivingClient, "donations");
    _givingsAdminClient = this.initRestClient(GivingsAdminClient, "donations");
    _givebritePaymentsClient = this.initRestClient(
      GivebritePaymentsClient,
      "payments",
    );

    _goCardlessPaymentsClient = this.initRestClient(
      GoCardlessPaymentsClient,
      "payments",
    );
    _justGivingPaymentsClient = this.initRestClient(
      JustGivingPaymentsClient,
      "payments",
    );
    _launchGoodPaymentsClient = this.initRestClient(
      LaunchGoodPaymentsClient,
      "payments",
    );
    _helloAssoPaymentClient = this.initRestClient(
      HelloAssoPaymentsClient,
      "payments",
    );
    _charitiesTrustPaymentsClient = this.initRestClient(
      CharitiesTrustPaymentsClient,
      "payments",
    );
    _cardTerminalPaymentClient = this.initRestClient(
      CardTerminalPaymentsClient,
      "payments",
    );

    _listsCallsConnectorClient = this.initRestClient(
      ListsCallsConnectorClient,
      "lists",
    );
    _listConnectionsClient = this.initRestClient(
      ListConnectionsClient,
      "lists",
    );
    _listsClient = this.initRestClient(ListsClient, "lists");
    _listsFolderClient = this.initRestClient(FoldersClient, "lists");
    _listsCorrespondenceConnectorClient = this.initRestClient(
      ListsCorrespondenceConnectorClient,
      "lists",
    );
    _listsDataDashboardClient = this.initRestClient(
      ListsDataDashboardClient,
      "lists",
    );
    _listsExportsClient = this.initRestClient(ListsExportsClient, "lists");
    _listsMailchimpConnectorClient = this.initRestClient(
      ListsMailchimpConnectorClient,
      "lists",
    );
    _listsTaskConnectorClient = this.initRestClient(
      ListsTaskConnectorClient,
      "lists",
    );
    _listsLookupsClient = this.initRestClient(ListsLookupsClient, "lists");
    _listStatisticsClient = this.initRestClient(ListStatisticsClient, "lists");
    _mailDataEntryClient = this.initRestClient(MailDataEntryClient, "mail");
    _mailLookupsClient = this.initRestClient(MailLookupsClient, "mail");
    _measuresClient = this.initRestClient(MeasuresClient, "analytics");
    _myTenNightsPaymentsClient = this.initRestClient(
      MyTenNightsPaymentsClient,
      "payments",
    );
    _muslimGivingPaymentsClient = this.initRestClient(
      MuslimGivingPaymentsClient,
      "payments",
    );
    _navGlobalSuggestionsClient = this.initRestClient(
      NavGlobalSuggestionsClient,
      "navigation",
    );
    _opayoPaymentsClient = this.initRestClient(OpayoPaymentsClient, "payments");
    _otherPaymentsClient = this.initRestClient(OtherPaymentsClient, "payments");
    _outboxesClient = this.initRestClient(OutboxesClient, "communications");
    _inboxesClient= this.initRestClient(InboxesClient,"reporting")
    _payPalPaymentsClient = this.initRestClient(
      PayPalPaymentsClient,
      "payments",
    );
    _paymentDataEntryClient = this.initRestClient(
      PaymentsDataEntryClient,
      "payments",
    );
    _paymentsDataImportClient = this.initRestClient(
      PaymentsDataImportClient,
      "payments",
    );
    _paymentMethodsClient = this.initRestClient(
      PaymentMethodsClient,
      "payments",
    );
    _paymentProcessorsClient = this.initRestClient(
      PaymentProcessorsClient,
      "payments",
    );
    _paymentsClient = this.initRestClient(PaymentsClient, "payments");
    _paymentsGlobalSuggestionsClient = this.initRestClient(
      PaymentsGlobalSuggestionsClient,
      "payments",
    );
    _paymentsLookupsClient = this.initRestClient(
      PaymentsLookupsClient,
      "payments",
    );
    _pdfCompositionsClient = this.initRestClient(
      PdfCompositionsClient,
      "templates",
    );
    _pledgesAdminClient = this.initRestClient(PledgesAdminClient, "pledges");
    _pledgesClient = this.initRestClient(PledgesClient, "pledges");
    _pledgesDataImportClient = this.initRestClient(
      PledgesDataImportClient,
      "pledges",
    );
    _pledgesGlobalSuggestionsClient = this.initRestClient(
      PledgesGlobalSuggestionsClient,
      "pledges",
    );
    _pledgesLookupsClient = this.initRestClient(
      PledgesLookupsClient,
      "pledges",
    );
    _printLookupsClient = this.initRestClient(PrintLookupsClient, "print");
    _printAdminClient = this.initRestClient(PrintAdminClient, "print");
    _queuesClient = this.initRestClient(QueuesClient, "dataimport");
    _quickNavigationClient = this.initRestClient(
      QuickNavigationClient,
      "navigation",
    );
    _recordsClient = this.initRestClient(RecordsClient, "dataimport");
    _reportsClient = this.initRestClient(ReportsClient, "analytics");
    _reportingClient = this.initRestClient(ReportingClient, "reporting");
    _reportingLookupsClient = this.initRestClient(
      ReportingLookupsClient,
      "reporting",
    );
    _routesClient = this.initRestClient(RoutesClient, "navigation");
    _runsClient = this.initRestClient(RunsClient, "dataimport");
    _securityPrincipalsClient = this.initRestClient(
      SecurityPrincipalsClient,
      "users",
    );
    _smsAdminClient = this.initRestClient(SmsAdminClient, "sms");
    _smsLookupsClient = this.initRestClient(SmsLookupsClient, "sms");
    _smsCompositionsClient = this.initRestClient(
      SmsCompositionsClient,
      "templates",
    );
    _smartDebitPaymentsClient = this.initRestClient(
      SmartDebitPaymentsClient,
      "payments",
    );
    _childSponsorshipClient = this.initRestClient(
      ChildSponsorshipClient,
      "sponsorships",
    );
    _otherSponsorshipClient = this.initRestClient(
      OtherSponsorshipClient,
      "sponsorships",
    );
    _sponsorshipBeneficiariesClient = this.initRestClient(
      BeneficiariesClient,
      "sponsorships",
    );
    _sponsorshipLookupsClient = this.initRestClient(
      SponsorshipLookups,
      "sponsorships",
    );
    _sponsorshipSchemesClient = this.initRestClient(
      SchemesClient,
      "sponsorships",
    );
    _sponsorshipsAdminClient = this.initRestClient(
      SponsorshipsAdminClient,
      "sponsorships",
    );
    _sponsorshipsClient = this.initRestClient(
      SponsorshipsClient,
      "sponsorships",
    );
    _sponsorshipsDashboardClient = this.initRestClient(
      SponsorshipsDashboardClient,
      "sponsorships",
    );
    _sponsorshipsDataEntryClient = this.initRestClient(
      SponsorshipsDataEntryClient,
      "sponsorships",
    );
    _sponsorshipsDataImportClient = this.initRestClient(
      SponsorshipsDataImportClient,
      "sponsorships",
    );
    _sponsorshipsGlobalSuggestionsClient = this.initRestClient(
      SponsorshipsGlobalSuggestionsClient,
      "sponsorships",
    );

    _stripePaymentsClient = this.initRestClient(
      StripePaymentsClient,
      "payments",
    );

    _squareTerminalPaymentClient = this.initRestClient(
      SquareTerminalPaymentsClient,
      "payments",
    );

    _subscriptionFundsClient = this.initRestClient(
      FundsClient,
      "subscriptions",
    );
    _subscriptionLookupsClient = this.initRestClient(
      SubscriptionLookups,
      "subscriptions",
    );
    _subscriptionOrganizationsClient = this.initRestClient(
      OrganizationClient,
      "subscriptions",
    );
    _subscriptionsClient = this.initRestClient(
      SubscriptionsClient,
      "subscriptions",
    );
    _tasksClient = this.initRestClient(TasksClient, "tasks");
    _taskGlobalSuggestionsClient = this.initRestClient(
      TaskGlobalSuggestionsClient,
      "tasks",
    );
    _taxReliefDonationsClient = this.initRestClient(
      TaxReliefDonationsClient,
      "taxrelief",
    );
    _taxReliefDataEntryClient = this.initRestClient(
      TaxReliefDataEntryClient,
      "taxrelief",
    );
    _taxReliefDeclarationsClient = this.initRestClient(
      TaxReliefDeclarationsClient,
      "taxrelief",
    );
    _taxReliefLookupsClient = this.initRestClient(
      TaxReliefLookupsClient,
      "taxrelief",
    );
    _taxReliefStatementsClient = this.initRestClient(
      TaxReliefStatementsClient,
      "taxrelief",
    );
    _taxReliefStatisticsClient = this.initRestClient(
      TaxReliefStatisticsClient,
      "taxrelief",
    );
    _taxReliefClaimsClient = this.initRestClient(
      TaxReliefClaimsClient,
      "taxrelief",
    );
    _taxReliefJobsClient = this.initRestClient(
      TaxlReliefJobsClient,
      "taxrelief",
    );
    _taxReliefAdminClient = this.initRestClient(
      TaxReliefAdminClient,
      "taxrelief",
    );
    _templatesLookupClient = this.initRestClient(
      TemplatesLookupsClient,
      "templates",
    );
    _tasksLookupsClient = this.initRestClient(TasksLookupsClient, "tasks");
    _taxReliefAccountsClient = this.initRestClient(
      TaxAccountsClient,
      "taxrelief",
    );
    _timeClockAdminClient = this.initRestClient(
      TimeClockAdminClient,
      "timeclock",
    );
    _timeClockLookupsClient = this.initRestClient(
      TimeClockLookupsClient,
      "timeclock",
    );
    _touchpointsClient = this.initRestClient(TouchpointsClient, "analytics");
    _transformsClient = this.initRestClient(TransformsClient, "dataimport");
    _usersLookupsClient = this.initRestClient(UsersLookupsClient, "users");
    _rolesClient = this.initRestClient(RolesClient, "users");
    _usersClient = this.initRestClient(UsersClient, "users");
    _usersDataImportClient = this.initRestClient(
      UsersDataImportClient,
      "users",
    );
    _virginMoneyGivingPaymentsClient = this.initRestClient(
      VirginMoneyGivingPaymentsClient,
      "payments",
    );
    _webHooksLookupsClient = this.initRestClient(WebHooksLoopupClient, "hooks");
    _webHooksClient = this.initRestClient(WebHooksClient, "hooks");
    _worldlinePaymentsClient = this.initRestClient(
      WorldlinePaymentsClient,
      "payments",
    );
    _taxSchemeClient = this.initRestClient(TaxSchemeClient, "taxrelief");
    _refundClient = this.initRestClient(RefundsClient, "refunds");
    _fundraisersClient = this.initRestClient(FundraisersClient, "analytics");
    _listAccountClient = this.initRestClient(ListAccountClient, "lists");
    _listGivingsClient = this.initRestClient(ListGivingsClient, "lists");
    _listPledgesClient = this.initRestClient(ListPledgesClient, "lists");
    _listSponsorshipsClient = this.initRestClient(
      ListSponsorshipsClient,
      "lists",
    );
    _listsSendGridClient = this.initRestClient(ListsSendGridClient, "lists");
  }

  initRestClient<T>(
    clientAPIType: any,
    apiUrlKey: IBaseUrlKeys,
    customConfiguration?: Partial<IConfig>,
  ): T {
    return this.getRestClient(
      clientAPIType,
      this.baseUrlFor(apiUrlKey),
      customConfiguration,
    );
  }

  getRestClient = <T>(
    RestClientNew: RestClient<T>,
    clientBaseUrl: string,
    customConfiguration?: Partial<IConfig>,
  ): T => {
    return new RestClientNew(
      { ...K2RestService.defaultClientConfiguration, ...customConfiguration },
      clientBaseUrl,
    );
  };

  baseUrlFor(key: IBaseUrlKeys) {
    if (!AppManager.baseUrls)
      throw new Error("baseUrls haven't been initialized yet");

    const baseUrl: string = AppManager.baseUrls[key];

    return baseUrl;
  }
}

export default new K2RestClients();
