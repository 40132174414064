import React, { FC, useCallback, useEffect, useRef, useState } from "react";

import {
  AgentRes,
  AppointmentRes,
  NextForDialerRes,
  NextForDialerType,
} from "@n3oltd/k2.calls.sdk.agents/esm";
import {
  AddOrRemoveDispositionReq,
  CallEndedReason,
  CreateAppointmentReq,
  EndCallReq,
} from "@n3oltd/k2.calls.sdk.calls";
import { CallRes } from "@n3oltd/k2.calls.sdk.calls/src";
import { Form } from "antd";
import { usePrevious, useRouter } from "hooks";
import moment from "moment";

import {
  _callsAgentsClient,
  _callsAppointmentsClient,
  _callsClient,
} from "appRedux/models/base/K2RestClients";
import K2Service from "appRedux/models/base/K2RestService";
import K2RestService from "appRedux/models/base/K2RestService";
import {
  IApiResponse,
  K2StatusCodes,
} from "appRedux/models/common/ApiResponseModel";
import { K2Routes } from "appRedux/models/routes/K2Routes";
import {
  CallDispositionsLookups,
  CallEndedReasonsLookups,
  SkipReasonsLookups,
  TimezoneLookups,
} from "appRedux/modules/lookups/types";
import { TimesHelpers } from "common/helpers/times";
import injectK2Intl from "components/k2Widgets/k2Localizations/injectK2Intl";
import { InjectedK2Intl } from "components/k2Widgets/k2Localizations/types";
import { UIUtils } from "components/utils";

import { OutBoundViewType } from "../../types";
import AppointmentModal from "../AppointmentModal";
import NotesModal from "../NotesModal";
import SkipRecordModal from "../SkipRecordModal";
import CallingView from "./CallingView";
import CancelAppointmentModal from "./CancelAppointmentModal";
import DispositionsView from "./DispositionsView";
import EndCallView from "./EndCallView";
import PreviewCall from "./PreviewCall";
import ReadyForCall from "./ReadyForCall";

interface OutboundDiallerProps {
  agent: AgentRes;
  k2Intl?: InjectedK2Intl;
  updatingAgent: boolean;
  agentLoading: boolean;
  callDuration: string;
  callingAccount: CallRes;
  callDispositions: CallDispositionsLookups;
  callEndedReasons: CallEndedReasonsLookups;
  callSkipReasons?: SkipReasonsLookups;
  timezones?: TimezoneLookups;
  setShowTabsAndBottom: (value: boolean) => void;
  setCallingAccount: (value: CallRes) => void;
  setLoading: (value: boolean) => void;
  onUpdateTeamAndCampaign: (
    agentId: string,
    teamId: string,
    campaignId: string,
  ) => void;
  refecthAgent: () => void;
  setCallDuration: (value: string) => void;
  setShowingPopover: (value: boolean) => void;
}

const TIME_OUT = 1000;
const DISMISS_SECONDS = 60 * 45;
export const CALL_DUARTION_START = "00:00";

const OutboundDialler: FC<OutboundDiallerProps> = ({
  agent,
  k2Intl,
  updatingAgent,
  callSkipReasons,
  callEndedReasons,
  callingAccount,
  callDispositions,
  timezones,
  callDuration,
  agentLoading,
  onUpdateTeamAndCampaign,
  setCallingAccount,
  setLoading,
  refecthAgent,
  setCallDuration,
  setShowTabsAndBottom,
  setShowingPopover,
}) => {
  const [openSkipModal, setOpenSkipModal] = useState(false);
  const [openCancelModal, setOpenCancelModal] = useState(false);
  const [nextRecord, setNextRecord] = useState<NextForDialerRes>(null);
  const [appointment, setAppointment] = useState<AppointmentRes>(null);
  const [outboundView, setOutboundView] = useState<OutBoundViewType>(
    OutBoundViewType.Ready,
  );
  const durationRef = useRef(null);
  const durationStartTimeRef = useRef(null);
  const durationContinue = useRef(true);
  const appointmentDataRef = useRef<EndCallReq>(null);
  const [longCallDismiss, setLongCallDismiss] = useState<boolean>(null);
  const [openAppointmentModal, setOpenAppointmentModal] = useState<boolean>(
    false,
  );
  const [openNotesModal, setOpenNotesModal] = useState<boolean>(false);
  const [scheduleAppointmentForm] = Form.useForm();

  const { type: recordType } = nextRecord || {};

  const { onCall: { begunAt, id: onCallId } = {}, isOnCall } = agent;

  const { begunAt: callBegunAt, id: callId } = callingAccount || {};

  const { viewAccount } = K2Routes;
  const router = useRouter();

  const previousAgentLoading = usePrevious(agentLoading);
  const previousOutboundView = usePrevious(outboundView);

  const getAppointment = useCallback(async (appointmentId: string) => {
    const appointmentRes: IApiResponse<AppointmentRes> = await K2Service.toResponse(
      _callsAppointmentsClient.getAppointmentById(appointmentId),
    );
    if (!appointmentRes.error) {
      setAppointment(appointmentRes.getResultOrDefault());
    }
  }, []);

  const setCallingOrPreviewAndRedirect = useCallback(
    (accountId: string, viewMode: OutBoundViewType, showPopver = false) => {
      setOutboundView(viewMode);
      router.history.push(viewAccount.replace(":accountId", accountId));
      setShowingPopover(showPopver);
      // eslint-disable-next-line
    },
    [router.history, setShowingPopover, viewAccount],
  );
  /**
   * Get the next record for the dialler
   */
  // !imp Need To Check Campgain Mode
  // !imp Progressive vs Preview
  const getNextRecord = useCallback(async () => {
    setLoading(true);

    const response: IApiResponse<NextForDialerRes> = await K2Service.toResponse(
      _callsAgentsClient.nextForDialer(),
    );

    if (response.error) {
      if (response.error.status === K2StatusCodes.preconditionFailed) {
        // There will be no form field errors, so show notification
        UIUtils.showValidationErrorsNotification(k2Intl, response.error);
      } else {
        UIUtils.handleServerError(k2Intl, response.error);
      }
      setLoading(false);
      return;
    }

    const record = response.getResultOrDefault();
    if (record.call?.hasAppointment) {
      getAppointment(record.call.appointment.id);
    } else setAppointment(null);
    setNextRecord(record);
    if (record.type === NextForDialerType.Call) {
      setCallingAccount(record.call);
      setCallingOrPreviewAndRedirect(
        record.call.account.id,
        OutBoundViewType.Calling,
      );
    } else if (record.type !== NextForDialerType.DataExhausted) {
      setCallingOrPreviewAndRedirect(
        record?.record?.account?.id ?? record?.appointment?.account?.id,
        OutBoundViewType.Preview,
        true,
      );
    }
    setLoading(false);
  }, [
    k2Intl,
    setLoading,
    setCallingAccount,
    getAppointment,
    setCallingOrPreviewAndRedirect,
  ]);
  const addDispositions = useCallback(
    async (values: AddOrRemoveDispositionReq) => {
      setLoading(true);
      let resp: IApiResponse<void> = await K2RestService.toResponse(
        _callsClient.addDisposition(callId, {
          ...values,
        }),
      );

      if (resp.error) {
        if (resp.error.status === K2StatusCodes.preconditionFailed) {
          // There will be no form field errors, so show notification
          UIUtils.showValidationErrorsNotification(k2Intl, resp.error);
        } else {
          UIUtils.handleServerError(k2Intl, resp.error);
        }
      }
      setLoading(false);
    },
    [k2Intl, callId, setLoading],
  );
  const removeDispositions = useCallback(
    async (values: AddOrRemoveDispositionReq) => {
      setLoading(true);
      let resp: IApiResponse<CallRes> = await K2RestService.toResponse(
        _callsClient.removeDisposition(callId, {
          ...values,
        }),
      );

      if (resp.error) {
        if (resp.error.status === K2StatusCodes.preconditionFailed) {
          // There will be no form field errors, so show notification
          UIUtils.showValidationErrorsNotification(k2Intl, resp.error);
        } else {
          UIUtils.handleServerError(k2Intl, resp.error);
        }
      }
      setLoading(false);
    },
    [k2Intl, callId, setLoading],
  );

  const onDone = useCallback(() => {
    refecthAgent();

    setTimeout(() => {
      setCallingAccount(null);
      setOutboundView(OutBoundViewType.Ready);
      setCallDuration(CALL_DUARTION_START);
    }, 1500);
  }, [refecthAgent, setCallDuration, setCallingAccount]);
  const confirmEndCall = useCallback(
    async (completesCall: boolean, reasonId: CallEndedReason = null) => {
      setLoading(true);

      let endCallReq: EndCallReq = {};
      if (reasonId) {
        endCallReq.reason = reasonId;
      } else {
        endCallReq = {
          ...appointmentDataRef.current,
        };
      }

      let callRes: IApiResponse<CallRes> = await K2RestService.toResponse(
        _callsClient.getCallById(callId),
      );

      if (callRes.error) {
        UIUtils.handleError(k2Intl, callRes.error);
        setLoading(false);
        return;
      }

      let endCallRes: IApiResponse<void> = await K2RestService.toResponse(
        _callsClient.endCall(
          callRes.getResultOrDefault().revisionId,
          endCallReq,
        ),
      );

      if (endCallRes.error) {
        if (endCallRes.error.status === K2StatusCodes.preconditionFailed) {
          // There will be no form field errors, so show notification
          UIUtils.showValidationErrorsNotification(k2Intl, endCallRes.error);
        } else {
          UIUtils.handleServerError(k2Intl, endCallRes.error);
        }
        setLoading(false);
        return;
      }
      durationContinue.current = false;
      if (appointmentDataRef.current) {
        scheduleAppointmentForm?.resetFields();
        appointmentDataRef.current = null;
      }
      if (completesCall) {
        setOutboundView(OutBoundViewType.Dispositions);
        setLongCallDismiss(null);
      } else {
        onDone();
      }

      setLoading(false);
    },
    [k2Intl, callId, scheduleAppointmentForm, setLoading, onDone],
  );

  const resumeCall = useCallback(async () => {
    const response: IApiResponse<void> = await K2RestService.toResponse(
      _callsAgentsClient.stillOnRecord(),
    );
    if (response.error) UIUtils.handleError(k2Intl, response.error);
    else setLongCallDismiss(false);
  }, [k2Intl]);

  const setEndCallView = useCallback(() => {
    durationContinue.current = false;
    if (appointmentDataRef.current) confirmEndCall(true, null);
    else setOutboundView(OutBoundViewType.EndCall);
  }, [confirmEndCall, setOutboundView]);

  const scheduleAppointment = useCallback(
    async (values: CreateAppointmentReq) => {
      let endCallReq: EndCallReq = {};
      endCallReq = {
        ...endCallReq,
        appointment: {
          ...values,
        },
        reason: CallEndedReason.Appointment,
      };

      appointmentDataRef.current = endCallReq;
      setOpenAppointmentModal(false);
    },
    [appointmentDataRef],
  );

  const onCancel = useCallback(() => {
    appointmentDataRef.current = null;
  }, [appointmentDataRef]);

  const onProcedToCall = useCallback(
    (call: CallRes) => {
      setCallingAccount(call);
      durationContinue.current = true;
      setOutboundView(OutBoundViewType.Calling);
    },
    [setCallingAccount],
  );

  const onBack = useCallback(() => {
    durationContinue.current = true;
    setOutboundView(OutBoundViewType.Calling);
  }, []);

  useEffect(() => {
    if (
      (outboundView === OutBoundViewType.Calling || isOnCall) &&
      !longCallDismiss
    ) {
      if (durationRef.current) {
        clearInterval(durationRef.current);
      }
      if (durationContinue.current) {
        durationStartTimeRef.current = isOnCall
          ? moment(begunAt)
          : moment(callBegunAt);
        durationRef.current = setInterval(() => {
          const diffSeconds = parseInt(
            `${moment().diff(durationStartTimeRef.current) / TIME_OUT}`,
            10,
          );
          setCallDuration(TimesHelpers.secondsToString(diffSeconds));
          if (longCallDismiss === null && diffSeconds >= DISMISS_SECONDS) {
            setLongCallDismiss(true);
          }
        }, TIME_OUT);
      }
    } else if (longCallDismiss) {
      const diffSeconds = parseInt(
        `${moment().diff(isOnCall ? moment(begunAt) : moment()) / TIME_OUT}`,
        10,
      );
      if (callDuration === CALL_DUARTION_START) {
        setCallDuration(TimesHelpers.secondsToString(diffSeconds));
      }
    }

    return () => {
      if (durationRef.current) {
        clearInterval(durationRef.current);
      }
    };
  }, [
    isOnCall,
    begunAt,
    outboundView,
    longCallDismiss,
    callDuration,
    callBegunAt,
    setCallDuration,
  ]);

  useEffect(() => {
    if (isOnCall && outboundView === OutBoundViewType.Ready && onCallId) {
      _callsClient
        .getCallById(onCallId)
        .then((response: CallRes) => {
          if (response.hasAppointment) {
            getAppointment(response.appointment.id);
          }
          setCallingAccount(response);
          setOutboundView(OutBoundViewType.Calling);
        })
        .catch((e) => {
          UIUtils.handleServerError(k2Intl, e.error);
        });
    }
  }, [
    isOnCall,
    onCallId,
    outboundView,
    k2Intl,
    setCallingAccount,
    getAppointment,
  ]);

  useEffect(() => {
    if (
      outboundView === OutBoundViewType.Ready ||
      outboundView === OutBoundViewType.Preview
    ) {
      setShowTabsAndBottom(true);
    } else {
      setShowTabsAndBottom(false);
    }
  }, [outboundView, setShowTabsAndBottom]);

  useEffect(() => {
    const outboundViewsList = [previousOutboundView, outboundView];
    if (
      previousAgentLoading &&
      !agentLoading &&
      (outboundViewsList.every(
        (view) => view === OutBoundViewType.Dispositions,
      ) ||
        outboundViewsList.every((view) => view === OutBoundViewType.EndCall))
    ) {
      setOutboundView(OutBoundViewType.Ready);
      setCallingAccount(null);
      setCallDuration(CALL_DUARTION_START);
    }
  }, [
    previousAgentLoading,
    agentLoading,
    previousOutboundView,
    outboundView,
    setCallingAccount,
    setCallDuration,
  ]);

  return (
    <>
      {outboundView === OutBoundViewType.Ready && (
        <ReadyForCall
          k2Intl={k2Intl}
          agent={agent}
          getNextRecord={getNextRecord}
          onUpdateTeamAndCampaign={onUpdateTeamAndCampaign}
          updatingAgent={updatingAgent}
          isDataExhausted={recordType === NextForDialerType.DataExhausted}
          setNextRecord={setNextRecord}
        />
      )}

      {outboundView === OutBoundViewType.Preview && nextRecord && (
        <PreviewCall
          k2Intl={k2Intl}
          recordToPreview={nextRecord}
          setOpenSkipModal={setOpenSkipModal}
          setLoading={setLoading}
          onProcedToCall={onProcedToCall}
          setOpenCancelModal={setOpenCancelModal}
          setAppointment={setAppointment}
        />
      )}

      {outboundView === OutBoundViewType.Calling && (
        <CallingView
          callDuration={callDuration}
          callingAccount={callingAccount}
          longCallDismiss={longCallDismiss}
          onResume={resumeCall}
          setOpenAppointmentModal={setOpenAppointmentModal}
          setEndCallView={setEndCallView}
          appointment={appointment}
        />
      )}
      {outboundView === OutBoundViewType.EndCall && (
        <EndCallView
          onBack={onBack}
          callDuration={callDuration}
          callEndedReasons={callEndedReasons}
          confirmEndCall={confirmEndCall}
        />
      )}

      {outboundView === OutBoundViewType.Dispositions && (
        <DispositionsView
          callDispositions={callDispositions}
          setOpenNotesModal={setOpenNotesModal}
          addDispositions={addDispositions}
          removeDispositions={removeDispositions}
          onDone={onDone}
          callDuration={callDuration}
        />
      )}

      <SkipRecordModal
        k2Intl={k2Intl}
        visible={openSkipModal}
        setOpenSkipModal={setOpenSkipModal}
        onSkipped={() => {
          setCallingAccount(null);
          setOutboundView(OutBoundViewType.Ready);
        }}
        skipReasons={callSkipReasons}
      />
      <CancelAppointmentModal
        revisionId={nextRecord?.appointment?.revisionId}
        k2Intl={k2Intl}
        visible={openCancelModal}
        setOpenCancelModal={setOpenCancelModal}
        onCancel={() => {
          setCallingAccount(null);
          setOutboundView(OutBoundViewType.Ready);
        }}
      />
      <NotesModal
        k2Intl={k2Intl}
        visible={openNotesModal}
        setOpenNotesModal={setOpenNotesModal}
        callId={callId}
      />
      {callingAccount && (
        <AppointmentModal
          k2Intl={k2Intl}
          visible={openAppointmentModal}
          setOpenModal={setOpenAppointmentModal}
          account={callingAccount}
          form={scheduleAppointmentForm}
          timezones={timezones}
          onSchedule={scheduleAppointment}
          onCancel={onCancel}
        />
      )}
    </>
  );
};

export default injectK2Intl(OutboundDialler);
